import Vue from "vue";
import uaaAxios from "@/utils/authAxios";

export default {
  getCurrentUserSubscription: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${process.env.VUE_APP_PLANS_API}/subscriptions`)
        .then((response) => {
          let subscription = Vue._.get(response, "data");
          subscription.payments.sort((a, b) => (b.paymentDate > a.paymentDate ? 1 : -1));
          resolve(subscription);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addPayment: function (subscriptionId, payment) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .put(`${process.env.VUE_APP_PLANS_API}/subscriptions/${subscriptionId}`, payment)
        .then((response) => {
          let subscription = Vue._.get(response, "data");
          subscription.payments.sort((a, b) => (b.paymentDate > a.paymentDate ? 1 : -1));
          resolve(subscription);
        })
        .catch((err) => reject(err));
    });
  },

  subscribe: function (transactionParams) {
    const subscription = Object.assign(transactionParams);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PLANS_API}/subscriptions/`, subscription)
        .then((response) => {
          let data = Vue._.get(response, "data");
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPdf: function (pdf) {
    return new Promise((resolve, reject) => {
      uaaAxios({
        url: `plans-api/subscriptions/pdf/${pdf}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  payPayu: function (payData, id, paymentType) {
    let payPath = paymentType === "ONCE" ? "pay" : "recurrent-payment-pay";
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PLANS_API}/subscriptions/${id}/${payPath}`, payData)
        .then((response) => {
          let data = Vue._.get(response, "data");
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  validateAndApply: function (code, amount, clientId) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(
          `${process.env.VUE_APP_DISCOUNTS_API}/discounts/validateAndApply/${code}?amount=${amount}&clientId=${clientId}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
