import projectApplicationStatusJSON from "@/utils/projectApplicationStatus";

const STEP_STATUS = {
  incomplete: 0,
  inProgress: 1,
  complete: 2,
};

const INITIAL_STATE = {
  foundationData: {
    dimension: null,
    subDimension: null,
  },
  tenderRequestFromDB: null,
  steps: [
    {
      path: "general-data",
      status: STEP_STATUS.inProgress,
      data: null,
    },
    {
      path: "optimization",
      status: STEP_STATUS.incomplete,
      data: null,
    },
    {
      path: "team",
      status: STEP_STATUS.incomplete,
      data: null,
    },
    {
      path: "confirmation",
      status: STEP_STATUS.incomplete,
      data: null,
    },
    {
      path: "finish",
      status: STEP_STATUS.incomplete,
      data: null,
    },
  ],
  readOnly: false,
};

const state = {
  steps: [...INITIAL_STATE.steps],
};

const getters = {
  state(state) {
    return state.tenderRequestFromDB;
  },
  getTenderRequest(state) {
    let tenderRequest = {};
    for (let step of state.steps) {
      if (step.data) {
        tenderRequest = { ...tenderRequest, ...step.data };
      }
    }
    return { ...state.tenderRequestFromDB, ...tenderRequest };
  },
  getStepIsLocked: (state) => (stepIndex) => state.steps[stepIndex].status === STEP_STATUS.incomplete,
  getStepIsComplete: (state) => (stepIndex) => state.steps[stepIndex].status === STEP_STATUS.complete,
  getStep: (state) => (stepIndex) => state.steps[stepIndex],
  getIsLastStep: (state) => (stepIndex) => stepIndex === state.steps.length - 2,
  getFormData(state) {
    let data = {};
    for (let step of state.steps) {
      data = { ...data, ...step.data };
    }
    return data;
  },
  getLastComplete(state) {
    let lastComple = {};
    state.steps.forEach((step, index) => {
      if (step.status === STEP_STATUS.inProgress) {
        lastComple = { step, index };
      }
    });
    return lastComple;
  },
  getIsAllComplete(state) {
    let tenderRequest = {};

    for (let step of state.steps) {
      if (step.data) {
        tenderRequest = { ...tenderRequest, ...step.data };
      }
    }
    tenderRequest = { ...state.tenderRequestFromDB, ...tenderRequest };

    if (
      tenderRequest.id &&
      tenderRequest.projectId &&
      tenderRequest.valueProposal &&
      tenderRequest.deliverables &&
      tenderRequest.budget &&
      tenderRequest.economicalOffer &&
      tenderRequest.period &&
      tenderRequest.generalConsiderations &&
      tenderRequest.processAndStrategyFile &&
      tenderRequest.workplan &&
      tenderRequest.teamDescription &&
      tenderRequest.teamCharacteristics &&
      tenderRequest.teamCharacteristics.length > 0 &&
      tenderRequest.status.id < 3
    ) {
      return true;
    }
    return false;
  },
};

const actions = {
  setInProgress({ commit }, index) {
    commit("SET_STEP_STATUS", { index, status: STEP_STATUS.inProgress });
  },
  setComplete({ commit }, index) {
    commit("SET_STEP_STATUS", { index, status: STEP_STATUS.complete });
  },
  setIncomplete({ commit }, index) {
    commit("SET_STEP_STATUS", { index, status: STEP_STATUS.incomplete });
  },
  unlockStep({ commit }, index) {
    commit("UNLOCK_STEP", index);
  },
  setStepData({ commit }, args) {
    commit("SET_STEP_DATA", args);
  },
  setTenderRequestFromDB({ commit }, tenderRequest) {
    commit("SET_TENDER_REQUEST_DB", tenderRequest);
  },
  setFormDataFromTenderRequest({ commit }, tenderRequest) {
    commit("SET_FORM_DATA_FROM_TENDER_REQUEST", tenderRequest);
  },
  resetStep({ commit }, index) {
    commit("SET_STEP_DATA", { index, data: null });
    commit("SET_STEP_STATUS", { index, status: STEP_STATUS.incomplete });
  },
  resetAll({ commit }) {
    commit("RESET_ALL");
  },
  updateTenderRequest({ commit }) {
    commit("UPDATE_TENDER_REQUEST");
  },
};

const mutations = {
  SET_STEP_STATUS(state, payload) {
    state.steps[payload.index].status = payload.status;
  },
  SET_STEP_DATA(state, payload) {
    state.steps[payload.index].data = payload.data;
    state.tenderRequestFromDB = {
      ...state.tenderRequestFromDB,
      ...payload.data,
    };
  },
  SET_TENDER_REQUEST_DB(state, tenderRequest) {
    state.tenderRequestFromDB = tenderRequest;
  },
  SET_FORM_DATA_FROM_TENDER_REQUEST(state, tenderRequest) {
    state.tenderRequestFromDB = tenderRequest;
    let steps = state.steps;

    if (tenderRequest.period.startDate) {
      tenderRequest.period.startDate = tenderRequest.period.startDate.split("T")[0];
    }

    if (tenderRequest.period.endDate) {
      tenderRequest.period.endDate = tenderRequest.period.endDateISO.split("T")[0];
    }

    steps[0].status = STEP_STATUS.complete;
    steps[1].status = STEP_STATUS.inProgress;
    steps[0].data = {
      id: tenderRequest.id,
      projectId: tenderRequest.projectId,
      valueProposal: tenderRequest.valueProposal,
      deliverables: tenderRequest.deliverables,
      budget: tenderRequest.budget,
      economicalOffer: tenderRequest.economicalOffer,
      period: tenderRequest.period,
    };

    steps[1].status = STEP_STATUS.complete;
    steps[2].status = STEP_STATUS.inProgress;
    steps[1].data = {
      generalConsiderations: tenderRequest.generalConsiderations,
      processAndStrategyFile: tenderRequest.processAndStrategyFile,
      workplan: tenderRequest.workplan,
    };

    steps[2].status = STEP_STATUS.complete;
    steps[3].status = STEP_STATUS.inProgress;
    steps[2].data = {
      teamDescription: tenderRequest.teamDescription,
      teamCharacteristics: tenderRequest.teamCharacteristics,
      aditionalAttachments: tenderRequest.aditionalAttachments,
    };

    state.readOnly =
      tenderRequest.status && tenderRequest.status.id !== projectApplicationStatusJSON.incompleteApplication.id;
  },
  UNLOCK_STEP(state, index) {
    if (state.steps[index].status === STEP_STATUS.incomplete) {
      state.steps[index].status = STEP_STATUS.inProgress;
    }
  },
  RESET_ALL(state) {
    state.steps.forEach((s) => {
      s.data = null;
      s.status = STEP_STATUS.incomplete;
    });
    state.steps[0].status = STEP_STATUS.inProgress;
    state.tenderRequestFromDB = null;
  },
  UPDATE_TENDER_REQUEST(state) {
    for (let step of state.steps) {
      if (step.data) {
        state.tenderRequestFromDB = {
          ...state.tenderRequestFromDB,
          ...step.data,
        };
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
