import uaaAxios from "../utils/authAxios";
import CallForTenderError from "../error/CallForTender/CallForTenderError";

const fileDownload = require("js-file-download");
const DEFAULT_ALTERNATIVE_NAME = "download.pdf";

const CallForTenderService = {
  /**
   *
   * @returns {promise<array>}
   */
  getProjects: async () => {
    try {
      const { data } = await uaaAxios.get(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects`);
      return data;
    } catch (error) {
      const message = "Error al obtener proyectos";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {String} projectId
   * @returns {promise<object>}
   */
  getProject: async (projectId) => {
    try {
      const { data } = await uaaAxios.get(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/${projectId}`);
      return data;
    } catch (error) {
      const message = "Error al obtener proyecto solicitado";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @returns {promise<array>}
   */
  getSpecialistsProjects: async () => {
    try {
      const { data } = await uaaAxios.get(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/specialists`);
      return data;
    } catch (error) {
      const message = "Error al obtener proyectos tipo especialista";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {String} projectId
   * @returns {promise<object>}
   */
  getStats: async (projectId) => {
    try {
      const { data } = await uaaAxios.get(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/${projectId}/stats`);
      return data;
    } catch (error) {
      const message = "Error al obtener 'stats'";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {String} projectId
   * @returns {promise<object>}
   */
  getSpecialistProject: async (projectId) => {
    try {
      const { data } = await uaaAxios.get(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/specialists/${projectId}`
      );
      return data;
    } catch (error) {
      const message = "Error al obtener proyecto tipo especialista";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {Object} project
   * @returns {promise<object>}
   */
  createProject: async (project) => {
    try {
      const { data } = await uaaAxios.post(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects`, project);
      return data;
    } catch (error) {
      const message = "Error al crear proyecto";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {String} projectId
   * @param {String} postulationId
   * @returns
   */
  award: async (projectId, postulationId) => {
    try {
      const { data } = await uaaAxios.post(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/toaward/${projectId}?tenderRequestId=${postulationId}`,
        {}
      );
      return data;
    } catch (error) {
      const message = "Error al adjudicar proyecto";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {Object} project
   * @returns
   */
  publishProject: async (project) => {
    try {
      const { data } = await uaaAxios.post(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/publish`, project);
      return data;
    } catch (error) {
      const message = "Error al publicar proyecto";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {String} projectId
   * @returns
   */
  deleteProject: async (projectId) => {
    try {
      const { data } = await uaaAxios.delete(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/${projectId}`);
      return data;
    } catch (error) {
      const message = "Error al eliminar proyecto";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} projectId
   * @param {*} technicalRequirements
   * @param {*} aditionalAttachment
   * @returns
   */
  addAttachments: async (projectId, technicalRequirements, aditionalAttachment) => {
    let formData = new FormData();
    technicalRequirements?.changed ?? formData.append("technicalRequirements", technicalRequirements);
    aditionalAttachment?.changed ?? formData.append("attachment", aditionalAttachment);
    try {
      const { data } = await uaaAxios.post(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/attachments/${projectId}`,
        formData
      );
      return data;
    } catch (error) {
      const message = "Error al agregar archivo adjunto al proyecto";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} requestTender
   * @returns
   */
  saveRequestTender: async (requestTender) => {
    try {
      const { data } = await uaaAxios.post(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests`, requestTender);
      return data;
    } catch (error) {
      const message = "Error al guardar solicitud de licitacion";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} id
   * @param {*} tenderRequest
   * @returns
   */
  addTenderRequestAttachments: async (id, tenderRequest) => {
    let formData = new FormData();
    let fileCount = 0;
    let {
      economicalOffer,
      processAndStrategyFile,
      generalConsiderations,
      teamCharacteristics,
      aditionalAttachments,
      workplan,
    } = tenderRequest;

    if (economicalOffer && !economicalOffer.id) {
      formData.append("economicalOffer", economicalOffer);
      fileCount++;
    }
    if (processAndStrategyFile && !processAndStrategyFile.id) {
      formData.append("processAndStrategyFile", processAndStrategyFile);
      fileCount++;
    }
    if (generalConsiderations && !generalConsiderations.id) {
      formData.append("generalConsiderations", generalConsiderations);
      fileCount++;
    }
    if (workplan && !workplan.id) {
      formData.append("workplan", workplan);
      fileCount++;
    }
    if (teamCharacteristics && teamCharacteristics.length > 0 && !teamCharacteristics[0].id) {
      teamCharacteristics.map((f) => formData.append("teamCharacteristics", f));
      fileCount = fileCount + teamCharacteristics.length;
    }
    if (aditionalAttachments && aditionalAttachments.length > 0 && !aditionalAttachments[0].id) {
      aditionalAttachments.map((f) => formData.append("aditionalAttachments", f));
      fileCount = fileCount + aditionalAttachments.length;
    }
    if (fileCount) {
      try {
        const { data } = await uaaAxios.post(
          `${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests/attachments/${id}`,
          formData
        );
        return data;
      } catch (error) {
        const message = "Error al guardar solicitud de licitacion";
        const errorCode = error.response.status;
        const url = error.request.responseURL;
        throw new CallForTenderError({ message, errorCode, url });
      }
    }
    return tenderRequest;
  },
  /**
   *
   * @param {*} id
   * @returns
   */
  getTenderRequest: async (id) => {
    try {
      const { data } = await uaaAxios.get(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests/${id}`);
      return data;
    } catch (error) {
      const message = "Error al obtener solicitud de licitacion";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} projectId
   * @returns
   */
  getLastRequestByProject: async (projectId) => {
    try {
      const { data } = await uaaAxios.get(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests?projectId=${projectId}`
      );
      return data;
    } catch (error) {
      const message = "Error al obtener ultima solicitud de licitacion";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} form
   * @returns
   */
  completeTenderRequest: async (form) => {
    try {
      const { data } = await uaaAxios.post(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests/complete`, form);
      return data;
    } catch (error) {
      const message = "Error al intentar completar solicitud de licitacion";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} attachmentId
   * @param {*} alternativeName
   * @returns
   */
  downloadAttachment: async (attachmentId, alternativeName) => {
    try {
      const { data, headers } = await uaaAxios.get(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/attachments/${attachmentId}`,
        { responseType: "blob" }
      );
      let contentDisposition = headers["content-disposition"];
      alternativeName = alternativeName ? alternativeName : DEFAULT_ALTERNATIVE_NAME;
      let fileName = contentDisposition ? contentDisposition.split("=")[1] : alternativeName;
      fileDownload(data, fileName);
      return data;
    } catch (error) {
      const message = "Error al descargar documento adjunto";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} requestId
   * @param {*} page
   * @returns
   */
  getQuestions: async (requestId, page) => {
    try {
      const { data } = await uaaAxios.get(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests/${requestId}/questions?page=${page ? page : ""}`
      );
      return data;
    } catch (error) {
      const message = "Error al obtener preguntas para licitacion";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} requestId
   * @param {*} question
   * @returns
   */
  createQuestion: async (requestId, question) => {
    try {
      const { data } = await uaaAxios.post(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests/${requestId}/questions`,
        question
      );
      return data;
    } catch (error) {
      const message = "Error al guardar pregunta para licitacion";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} requestId
   * @param {*} chatId
   * @returns
   */
  getMessages: async (requestId, chatId) => {
    try {
      const { data } = await uaaAxios.get(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests/${requestId}/chats/${chatId ? chatId : ""}`
      );
      return data;
    } catch (error) {
      const message = "Error al obtener mensajes";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} requestId
   * @param {*} message
   * @returns
   */
  createMessage: async (requestId, message) => {
    try {
      const { data } = await uaaAxios.post(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests/${requestId}/chats`,
        message
      );
      return data;
    } catch (error) {
      const message = "Error al enviar mensaje";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} projectId
   * @returns
   */
  cancelProject: async (projectId) => {
    try {
      const { data } = await uaaAxios.post(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/cancel?projectId=${projectId}`
      );
      return data;
    } catch (error) {
      const message = "Error al cancelar proyecto";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} project
   * @returns
   */
  editPublishProject: async (project) => {
    try {
      const { data } = await uaaAxios.post(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/editProjectPublish`,
        project
      );
      return data;
    } catch (error) {
      const message = "Error al editar proyecto publicado";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} id
   * @returns
   */
  deleteTender: async (id) => {
    try {
      const { data } = await uaaAxios.delete(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests?id=${id}`);
      return data;
    } catch (error) {
      const message = "Error al eliminar licitacion";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} projecId
   * @returns
   */
  getMatchList: async (projecId) => {
    try {
      const { data } = await uaaAxios.get(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-match?projectId=` + projecId
      );
      return data;
    } catch (error) {
      const message = "Error al obtener lista de emparejamiento";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} id
   * @returns
   */
  cancelRequest: async (id) => {
    try {
      const { data } = await uaaAxios.put(`${process.env.VUE_APP_CALL_FOR_TENDER_API}/tender-requests/cancel/${id}`);
      return data;
    } catch (error) {
      const message = "Error al eliminar solicitud";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
  /**
   *
   * @param {*} id
   * @returns
   */
  acceptEndPhase: async (id) => {
    try {
      const { data } = await uaaAxios.post(
        `${process.env.VUE_APP_CALL_FOR_TENDER_API}/projects/awardconfirm?tenderRequestId=${id}`
      );
      return data;
    } catch (error) {
      const message = "Error al aceptar fase final";
      const errorCode = error.response.status;
      const url = error.request.responseURL;
      throw new CallForTenderError({ message, errorCode, url });
    }
  },
};
export default CallForTenderService;
