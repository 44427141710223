import uaaAxios from "@/utils/authAxios";

const CurrentUserService = {
  /**
   * Funcion para obtener el cliente del usuario autenticado.
   *
   * @return client
   */
  currentClient: async (clientId) => {
    let { data } = await uaaAxios.get(`${process.env.VUE_APP_UMAPI_API}/clients/${clientId}`);
    return data;
  },
  /**
   * Funcion para obtener el usuario autenticado.
   *
   * @return user
   */
  currentUser: async () => {
    try {
      const { data } = await uaaAxios.get("/uaa/users/current");
      return data;
    } catch (error) {
      throw new Error("error al obtener el usuario");
    }
  },

  /**
   * Servicio par obtener el usuario a partir de la informacion del localStorage.
   *
   * @returns currentUser
   */
  currentUserData: async () => {
    const userId = localStorage.getItem("userId");
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_UMAPI_API}/users/${userId}`);
    return data;
  },

  /**
   *
   * @param {*} user
   * @returns
   */
  updateUserData: async (user) => {
    let userId = localStorage.getItem("userId");
    const { data } = await uaaAxios.put(`${process.env.VUE_APP_UMAPI_API}/users/${userId}`, user);
    return data;
  },

  /**
   *
   * @param {*} passwords
   * @returns
   */
  changePassword: async (passwords) => {
    let userId = localStorage.getItem("userId");
    const { data } = await uaaAxios.post(`${process.env.VUE_APP_UMAPI_API}/users/${userId}/password`, passwords);
    return data;
  },
};

export default CurrentUserService;
