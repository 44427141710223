import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      name: "root",
      path: "/",
      beforeEnter: (to, from, next) => {
        store
          .dispatch("authentication/isAuthenticated")
          .then(() => next("/login"))
          .catch(() => next("/home"));
      },
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/Login"),
      meta: {
        requiresAuth: false,
        layout: "no-auth-layout",
      },
    },
    {
      name: "signup",
      path: "/signup",
      component: () => import("@/views/registration/RegistrationForm"),
      meta: {
        requiresAuth: false,
        layout: "no-auth-layout",
      },
    },
    {
      name: "registration",
      path: "/registration",
      component: () => import("@/views/registration/RegistrationForm"),
      meta: {
        requiresAuth: false,
        layout: "no-auth-layout",
      },
    },
    {
      name: "invitedRegistration",
      path: "/registration/:hash",
      component: () => import("@/views/registration/RegistrationFormInvited"),
      meta: {
        requiresAuth: false,
        layout: "no-auth-layout",
      },
    },
    {
      name: "dtiFormInvited",
      path: "/dtiFormInvited",
      component: () => import("@/views/forms/DtiFormInvited"),
      meta: {
        requiresAuth: false,
        layout: "no-auth-layout",
      },
    },
    {
      name: "dtiEnding",
      path: "/dtiEnding",
      component: () => import("@/views/forms/DtiEnding"),
      meta: {
        requiresAuth: false,
        layout: "no-auth-layout",
      },
    },
    {
      name: "confirmation",
      path: "/confirmation",
      component: () => import("@/views/registration/ActivationEmailSent"),
      meta: {
        requiresAuth: false,
        layout: "no-auth-layout",
      },
    },
    {
      name: "activation",
      path: "/activation/:id",
      component: () => import("@/views/registration/ActivationForm"),
      meta: {
        requiresAuth: false,
        requiredBoth: true,
        layout: "no-auth-layout",
      },
    },
    {
      name: "forgot-password",
      path: "/forgot-password",
      component: () => import("@/views/ForgotPassword"),
      meta: {
        requiresAuth: false,
        layout: "no-auth-layout",
      },
    },
    {
      name: "password-recovery",
      path: "/password-recovery/:token",
      component: () => import("@/views/PasswordRecovery"),
      meta: {
        requiresAuth: false,
        layout: "no-auth-layout",
      },
    },
    {
      name: "home",
      path: "/home",
      component: () => import("@/views/Home"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_HOME",
      },
    },
    {
      name: "inviteds",
      path: "/inviteds",
      component: () => import("@/views/Inviteds"),
      meta: {
        isAdmin: true,
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_INVITEDS",
      },
    },
    {
      name: "trends-home-post",
      path: "/trends/:id",
      component: () => import("@/components/trends/TrendPost"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_TRENDS",
      },
    },
    {
      name: "trends-home-post-getabstract",
      path: "/trends-getabstract/:id",
      component: () => import("@/components/trends/TrendPostGetAbstract"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_TRENDS",
      },
    },
    {
      name: "dashboard",
      path: "/dashboard",
      component: () => import("@/views/Dashboard"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_IMTD_DASHBOARD",
      },
    },

    {
      name: "dashboard-imo",
      path: "/dashboard-imo",
      component: () => import("@/views/DashboardIMO"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_IMO_DASHBOARD",
      },
    },

    {
      name: "news",
      path: "/news",
      component: () => import("@/views/News"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
      },
    },
    {
      name: "trends",
      path: "/trends",
      component: () => import("@/views/Trends"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_TRENDS",
      },
    },
    {
      name: "my-profile",
      path: "/my-profile",
      component: () => import("@/views/profile/Profile"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROFILE",
      },
    },
    {
      name: "my-profile-new",
      path: "/my-profile-new",
      component: () => import("@/views/profile/ProfileNew"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROFILE",
      },
    },
    {
      name: "plans",
      path: "/plans",
      component: () => import("@/views/plans/Plans"),
      meta: {
        isAdmin: true,
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PLAN",
      },
    },
    {
      name: "edit-profile",
      path: "/my-profile/edit",
      component: () => import("@/views/profile/EditUserProfile"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROFILE",
      },
    },
    {
      name: "change-password",
      path: "/my-profile/change-password",
      component: () => import("@/views/profile/ChangePassword"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROFILE",
      },
    },
    {
      name: "complete-profile",
      path: "/complete-profile",
      component: () => import("@/views/profile/CompleteProfile"),
      meta: {
        requiresAuth: true,
        layout: "no-auth-layout",
        authority: "AUTH_PROFILE_COMPLETION",
      },
    },
    {
      name: "subscription",
      path: "/subscription",
      component: () => import("@/views/Subscription"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PLAN",
      },
    },
    {
      name: "subscribe",
      path: "/subscribe/:plan",
      component: () => import("@/views/Subscribe"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PLAN",
      },
    },
    {
      name: "payu",
      path: "/payu",
      component: () => import("@/views/Subscription/PayUForm"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PLAN",
      },
    },
    {
      name: "dti-form",
      path: "/dti-form",
      component: () => import("@/views/forms/Form"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_IMTD_SURVEY",
      },
    },
    {
      name: "survey",
      path: "/survey",
      component: () => import("@/views/forms/Survey"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_IMTD_SURVEY",
      },
    },
    {
      name: "survey-imo",
      path: "/survey-imo",
      component: () => import("@/views/survey/SurveyIMO"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_IMO_SURVEY",
      },
    },

    {
      name: "dashboardimension",
      path: "/dashboardimension/:id",
      component: () => import("@/views/DashboardDimension"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_IMTD_DASHBOARD",
      },
    },
    // ====================================+
    // PAGOS
    // ====================================+
    {
      name: "billing-result",
      path: "/billing/voucher/:id",
      component: () => import("@/views/billing/PaymentResult"),
      meta: {
        requiresAuth: true,
        layout: "no-auth-layout",
        authority: "AUTH_PLAN",
      },
    },
    {
      name: "enroll-result",
      path: "/billing/oneclick/enroll/:success",
      component: () => import("@/views/billing/OneClickEnrollResult"),
      meta: {
        requiresAuth: true,
        layout: "no-auth-layout",
        authority: "AUTH_PLAN",
      },
    },
    {
      name: "webpayplus-result",
      path: "/billing/webpayplus/:success",
      component: () => import("@/views/billing/WebpayPlusResult"),
      meta: {
        requiresAuth: true,
        layout: "no-auth-layout",
        authority: "AUTH_PLAN",
      },
    },
    {
      name: "success-payment",
      path: "/billing/payment/success",
      component: () => import("@/views/billing/SuccessfulPayment"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PLAN",
      },
      props: (route) => ({ query: route.query.paymentMethod }),
    },
    {
      name: "success-payment-payu",
      path: "/billing/payment/payu/success",
      component: () => import("@/views/billing/SuccessfulPaymentPayu"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PLAN",
      },
      //props: route => ({ paymentData : route.params })
    },
    {
      name: "error-payment",
      path: "/billing/payment/error",
      component: () => import("@/views/billing/ErrorPayment"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PLAN",
      },
    },
    {
      name: "my-payment",
      path: "/billing/mypayments/",
      component: () => import("@/views/billing/MyPayments"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PLAN",
      },
    },
    // ====================================+
    // Ranking
    // ====================================+
    {
      name: "ranking",
      path: "/ranking",
      component: () => import("@/views/Ranking"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_RANKING",
      },
    },
    // ====================================+
    // BRIEFCASE(PORTAFOLIO)
    // ====================================+
    {
      name: "briefcase",
      path: "/briefcase",
      component: () => import("@/views/briefcase/MyBriefcase"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PORTFOLIO",
      },
    },
    {
      name: "evaluation_client",
      path: "/evaluation_client/:id",
      component: () => import("@/views/briefcase/EvaluationClient"),
      meta: {
        requiresAuth: true,
        layout: "no-auth-layout",
        authority: "AUTH_PORTFOLIO",
      },
    },
    {
      name: "succesful-evaluation",
      path: "/succesful-evaluation",
      component: () => import("@/views/briefcase/SuccesfulEvaluation"),
      meta: {
        requiresAuth: true,
        layout: "no-auth-layout",
        authority: "AUTH_PORTFOLIO",
      },
    },
    // ====================================+
    // LICITACIÓN
    // ====================================+
    {
      name: "project-foundation",
      path: "/project-foundation",
      component: () => import("@/views/projects/Foundation"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROJECTS",
      },
    },
    {
      name: "my-projects/created",
      path: "/my-projects/created/:projectId",
      redirect: () => ({ name: "my-projects" }),
    },
    {
      name: "my-projects/request-created",
      path: "/my-projects/request-created/:tenderRequestId",
      redirect: () => ({ name: "my-projects" }),
    },
    {
      name: "my-projects",
      path: "/my-projects",
      component: () => import("@/views/projects/MyProjects"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROJECTS",
      },
    },
    {
      name: "applications",
      path: "/my-projects/applications/:projectId",
      component: () => import("@/views/projects/Applications"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROJECTS",
      },
    },
    {
      name: "create-project",
      path: "/create-project/:projectId",
      redirect: "/create-project/:projectId/GeneralData",
      component: () => import("@/views/projects/CreateProject"),
      children: [
        {
          path: "GeneralData",
          component: () => import("@/views/projects/steps/GeneralData"),
          meta: {
            step: 0,
            authority: "AUTH_PROJECTS",
          },
        },
        {
          path: "improvements",
          component: () => import("@/views/projects/steps/Improvements"),
          meta: {
            step: 1,
            authority: "AUTH_PROJECTS",
          },
        },
        {
          path: "Requeriments",
          component: () => import("@/views/projects/steps/Requeriments"),
          meta: {
            step: 2,
            authority: "AUTH_PROJECTS",
          },
        },
        {
          path: "Confirmation",
          component: () => import("@/views/projects/steps/Confirmation"),
          meta: {
            step: 3,
            authority: "AUTH_PROJECTS",
          },
        },
      ],
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROJECTS",
      },
    },
    {
      name: "project-presentation",
      path: "/project-presentation/:projectId",
      component: () => import("@/views/specialist-projects/ProjectPresentation"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROJECTS",
      },
    },
    {
      name: "project-description",
      path: "/project-description/:projectId",
      component: () => import("@/views/specialist-projects/ProjectDescription"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROJECTS",
      },
    },
    {
      name: "project-detail",
      path: "/project-detail/:projectId",
      component: () => import("@/views/projects/ProjectDetail"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROJECTS",
      },
    },
    {
      name: "apply-for-project",
      path: "/apply-for-project/:id",
      redirect: "/apply-for-project/:id/general-data",
      component: () => import("@/views/specialist-projects/ApplyForProject"),
      children: [
        {
          path: "general-data",
          component: () => import("@/views/specialist-projects/apply-for-project/GeneralData"),
          meta: {
            isAdmin: true,
            step: 0,
            authority: "AUTH_PROJECTS",
          },
        },
        {
          path: "optimization",
          component: () => import("@/views/specialist-projects/apply-for-project/Organization"),
          meta: {
            isAdmin: true,
            step: 1,
            authority: "AUTH_PROJECTS",
          },
        },
        {
          path: "team",
          component: () => import("@/views/specialist-projects/apply-for-project/Team"),
          meta: {
            isAdmin: true,
            step: 2,
            authority: "AUTH_PROJECTS",
          },
        },
        {
          path: "confirmation",
          component: () => import("@/views/specialist-projects/apply-for-project/Confirmation"),
          meta: {
            isAdmin: true,
            step: 3,
            authority: "AUTH_PROJECTS",
          },
        },
      ],
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROJECTS",
      },
    },
    {
      name: "project-tracking",
      path: "/project-tracking/:projectId",
      component: () => import("@/views/specialist-projects/ProjectTracking"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PROJECTS",
      },
    },
    // ====================================+
    // PMO DIGITAL
    // ====================================+
    {
      name: "pmo-digital",
      path: "/pmo-digital/:projectId",
      component: () => import("@/views/pmo/PmoDigital"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    // =================================
    // DASHBOARD
    //==================================
    {
      name: "dashboard-pmo",
      path: "/dashboard-pmo/:enterpriseId?/:projectId?",
      component: () => import("@/views/pmo/Dashboard"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "dashboard-bsc",
      path: "/dashboard-bsc",
      component: () => import("@/views/pmo/DashboardBSC"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "project-pmo",
      path: "/project-pmo/:projectId?",
      component: () => import("@/views/pmo/Dashboard"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-budget",
      path: "/pmo-digital/budget",
      component: () => import("@/views/pmo/Budget"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-budget-configuration",
      path: "/pmo-digital/configuration",
      component: () => import("@/views/pmo/Configuration"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-ficha",
      path: "/pmo-digital/ficha/:project",
      component: () => import("@/views/pmo/Ficha"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-planification",
      path: "/pmo-digital/planification",
      component: () => import("@/views/pmo/Planification"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-budget-seguimiento",
      path: "/pmo-digital/seguimiento",
      component: () => import("@/views/pmo/Seguimiento"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-risk",
      path: "/pmo-digital/risk",
      component: () => import("@/views/pmo/Risk"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-objectives",
      path: "/pmo-digital/objectives",
      component: () => import("@/views/pmo/Objectives"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-kpibusiness",
      path: "/pmo-digital/kpibusiness",
      component: () => import("@/views/pmo/KPIBusiness"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-organizational-agility",
      path: "/pmo-digital/organizational-agility",
      component: () => import("@/views/pmo/OrganizationalAgility"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-engagement",
      path: "/pmo-digital/engagement",
      component: () => import("@/views/pmo/Engagement"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-performance",
      path: "/pmo-digital/performance",
      component: () => import("@/views/pmo/Performance"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-profitability",
      path: "/pmo-digital/profitability",
      component: () => import("@/views/pmo/Profitability"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-efficiency",
      path: "/pmo-digital/efficiency",
      component: () => import("@/views/pmo/Efficiency"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    {
      name: "pmo-digital-productivity",
      path: "/pmo-digital/productivity",
      component: () => import("@/views/pmo/Productivity"),
      meta: {
        requiresAuth: true,
        layout: "app-layout",
        authority: "AUTH_PMO",
      },
    },
    // ====================================+
    // SETTINGS
    // ====================================+
    /*  {
      name: "settings",
      path: "/settings",
      component: () => import("@/views/Settings"),
      meta: {
        isAdmin: true,
        requiresAuth: true,
        layout: "app-layout",
      },
    },*/
    // ====================================+
    // ERRORS
    // ====================================+
    {
      name: "404",
      path: "*",
      component: () => import("@/views/errors/404"),
      meta: {
        requiredBoth: true,
        layout: "no-auth-layout",
        metaTags: [
          {
            name: "robots",
            content: "noindex",
          },
        ],
      },
    },
  ],
});
