import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import VueLogger from "vuejs-logger";
import VueLodash from "vue-lodash";
import VueGtag from "vue-gtag";
import lodash from "lodash";
import { VueReCaptcha } from "vue-recaptcha-v3";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import moment from "moment";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import onlyNumberValidatorService from "@/services/onlyNumberValidatorService";

import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@/assets/styles/main.scss";
import "@/assets/styles/typeform.scss";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

import TransbankForm from "transbank-vue";
Vue.use(TransbankForm);

import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);

import { CarbonIconsVue } from "@carbon/icons-vue";
import Bee from "@carbon/icons-vue/es/bee/32";
import Chat from "@carbon/icons-vue/es/chat/32";
import UserCertification from "@carbon/icons-vue/es/user--certification/24";

Vue.use(CarbonIconsVue, {
  components: {
    Bee,
    Chat,
    UserCertification,
  },
});

import Vue2Filters from "vue2-filters";

const Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false,
  },
  number: {
    format: "0",
    thousandsSeparator: ".",
    decimalSeparator: ",",
  },
  bytes: {
    decimalDigits: 2,
  },
  percent: {
    decimalDigits: 2,
    multiplier: 100,
    decimalSeparator: ",",
  },
  currency: {
    symbol: "$",
    decimalDigits: 2,
    thousandsSeparator: ".",
    decimalSeparator: ",",
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false,
  },
  pluralize: {
    includeNumber: false,
  },
  ordinal: {
    includeNumber: false,
  },
};

Vue.use(Vue2Filters, Vue2FiltersConfig);

Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: process.env.NODE_ENV === "production" ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
});

Vue.use(VueLodash, { lodash: lodash });

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
});

Vue.use(vueNumeralFilterInstaller, { locale: "es" });

Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
  }
});
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
Vue.filter("formatDateHour", function (value) {
  if (value) {
    return moment(String(value)).format("HH:mm:ss");
  }
});

Vue.filter("formatToISO", function (value) {
  if (value) {
    return moment(String(value)).toISOString();
  }
});

Vue.filter("reverse", function (value) {
  return value.slice().reverse();
});

Vue.filter("protectedPaymentCard", function (value) {
  let noVisible = "";
  let values = value;
  while (noVisible.length < values.length) {
    noVisible = noVisible + "*";
  }
  return `${noVisible}${values.slice(values.length - 4)}`;
});

Vue.directive("onlyNumbers", {
  bind(el) {
    let input = el.getElementsByTagName("input")[0];
    input.addEventListener("keydown", (evt) => {
      // Actual standard
      if (evt.key) {
        if (!onlyNumberValidatorService.validateFromKey(evt)) {
          evt.preventDefault();
        }
      }

      // Old explorers
      if (evt.keyCode) {
        if (!onlyNumberValidatorService.validateFromKeyCode(evt)) {
          evt.preventDefault();
        }
      }
    });
    input.addEventListener("paste", (evt) => {
      let regex = new RegExp(/[^0-9]/);

      // Use window object in IE 11
      let clipboardData = evt.clipboardData || window["clipboardData"];
      let clipboardValue = clipboardData.getData("text");

      if (regex.test(clipboardValue)) {
        evt.preventDefault();
      }
    });
  },
});

Vue.directive("creditCard", {
  bind(el) {
    let input = el.getElementsByTagName("input")[0];

    input.addEventListener("keydown", (evt) => {
      // Actual standard
      if (evt.key) {
        if (!onlyNumberValidatorService.validateFromKey(evt)) {
          evt.preventDefault();
        }
      }

      // Old explorers
      if (evt.keyCode) {
        if (!onlyNumberValidatorService.validateFromKeyCode(evt)) {
          evt.preventDefault();
        }
      }
    });

    input.addEventListener("paste", (evt) => {
      let regex = new RegExp(/[^0-9]/);

      // Use window object in IE 11
      let clipboardData = evt.clipboardData || window["clipboardData"];
      let clipboardValue = clipboardData.getData("text");

      if (regex.test(clipboardValue.replace(/ /g, "").replace(/-/g, ""))) {
        evt.preventDefault();
      }
    });

    input.addEventListener("input", (evt) => {
      let value = evt.target.value.replace(/ /g, "").replace(/-/g, "");
      let formatedText = "";
      if (value.length <= 4) {
        formatedText = value;
      } else {
        formatedText = value.slice(0, 4);
        for (let i = 4; i < value.length; i = i + 4) {
          formatedText = formatedText + " " + value.slice(i, i + 4);
        }
      }
      evt.target.value = formatedText;
    });
  },
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

  if (nearestWithMeta) {
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement("meta");

        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute("data-vue-router-controlled", "");

        return tag;
      })
      // Add the meta tags to the document head.
      .forEach((tag) => document.head.appendChild(tag));
  }
  if (to.matched.some((record) => record.meta.requiredBoth)) {
    next();
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      store.dispatch("authentication/setSidebarContent");
      store.dispatch("authentication/isCorporative");
      store.dispatch("UserStore/setUaaUserData");
      store
        .dispatch("authentication/isAuthenticated")
        .then(() => {
          const authorities = store.getters["UserStore/getUserAuthorities"];
          if (authorities.length > 0) {
            const authority = authorities.find((e) => e.authority == to.meta.authority);
            to.matched.some((record) => record.meta.authority)
              ? authority
                ? next()
                : next({ name: "plans" })
              : next({ name: "*" });
          } else {
            store.dispatch("authentication/doLogout");
          }
        })
        .catch(() => {
          next({ path: "login", query: { redirect: to.name } });
        });
    } else {
      store
        .dispatch("authentication/isAuthenticated")
        .then(() => {
          next({ path: "home" });
        })
        .catch(() => next());
    }
  }
});

// Setting up layout
router.afterEach((to) => {
  store.dispatch("layout/setLayout", to.meta.layout || "app-layout");
});

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GOOGLE_TAG_SITE_KEY,
    },
  },
  router
);

Sentry.init({
  Vue,
  dsn: "https://9cd375f4aa234bcc8df6898c8f50307f@o569298.ingest.sentry.io/5716236",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  tracingOptions: {
    trackComponents: true,
  },
  logErrors: true,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  release: process.env.VUE_APP_TWAY_VERSION,
});

Vue.config.devtools = process.env.NODE_ENV !== ("production" && "demo" && "test" && "testing");

new Vue({
  vuetify,
  router,
  store,
  data() {
    return {
      previus: null,
    };
  },
  render: (h) => h(App),
}).$mount("#app");
