import Vue from "vue";
import LoginService from "@/services/loginService";
import CurrentUserService from "@/services/currentUserService";
import CompanyService from "@/services/companyService";
import dtService from "@/services/dtService";
import currentUserService from "../../services/currentUserService";
import sidebarContentOptions from "@/utils/sidebarContent";

const state = {
  authenticated: false,
  userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
  userName: "",
  authInfo: {},
  client: {},
  accessToken: localStorage.getItem("accessToken") || "",
  sidebarContent: [],
  clientInfo: {},
  corporative: false,
};

const getters = {
  getClientInfo: (state) => state.clientInfo,
  hasUser(state) {
    return Object.keys(state.authInfo).length != 0;
  },
  isAuth(state) {
    return Object.values(state.accessToken).length != 0;
  },
  Auth(state) {
    return state.accessToken;
  },
  getSidebarContent: (state) => state.sidebarContent,
  getCorporative: (state) => state.corporative,
};

const actions = {
  doLogin: async ({ commit }, credentials) => {
    commit("CLEAN_LOCAL_STORAGE");
    const { username, password } = credentials;
    try {
      const grandTye = "password";
      var authInfo = await LoginService.login(username, password, grandTye);
    } catch (error) {
      console.log(error);
    }
    if (authInfo.isAxiosError) {
      commit("SET_AUTHENTICATION_STATUS", false);
      return false;
    } else {
      const { access_token } = authInfo;
      commit("SET_AUTHENTICATION_STATUS", true);
      commit("SET_AUTH_INFO", authInfo);
      commit("SET_ACCESS_TOKEN", access_token);
      try {
        var userInfo = await CurrentUserService.currentUser(access_token);
        commit("SET_USER_INFO", userInfo);
      } catch (error) {
        console.log(error);
      }
      try {
        var client = await CurrentUserService.currentClient(userInfo.principal.clientId);
        commit("SET_CLIENT", client);
      } catch (error) {
        console.log(error);
      }
      try {
        var company = await CompanyService.getCompanyProfile();
      } catch (error) {
        console.log(error);
      }
      if (company.emptyProfile) {
        return { name: "complete-profile" };
      } else {
        commit("SET_CLIENT_INFO", company);
        return { name: "home" };
      }
    }
  },
  doLogout: function ({ commit }) {
    return new Promise((resolve) => {
      commit("LOGOUT");
      resolve();
    });
  },
  doRegister: function ({ commit }, credentials) {
    commit("CLEAN_LOCAL_STORAGE");
    localStorage.setItem("userName", credentials.firstName);
    return new Promise((resolve, reject) => {
      commit("SET_AUTHENTICATION_STATUS", false);
      LoginService.register(
        Vue._.get(credentials, "firstName"),
        Vue._.get(credentials, "lastName"),
        Vue._.get(credentials, "companyName"),
        Vue._.get(credentials, "companyIdentifier"),
        Vue._.get(credentials, "email"),
        Vue._.get(credentials, "clientType"),
        Vue._.get(credentials, "countryCode"),
        Vue._.get(credentials, "source"),
        Vue._.get(credentials, "token"),
        Vue._.get(credentials, "businessParams"),
        Vue._.get(credentials, "promo"),
        Vue._.get(credentials, "metadata")
      ).then((authInfo) => {
        if (Vue._.get(authInfo, "isAxiosError")) {
          commit("SET_AUTHENTICATION_STATUS", false);
          reject();
        }
        resolve(true);
      });
    });
  },
  doUserInvitedRegister({ commit }, credentials) {
    commit("CLEAN_LOCAL_STORAGE");
    localStorage.setItem("userName", credentials.firstName);
    commit("SET_AUTHENTICATION_STATUS", false);
    let data = {
      username: credentials.email,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      clientType: credentials.clientType,
      countryCode: credentials.countryCode,
      businessParams: credentials.businessParams,
      metadata: credentials.metadata,
    };
    let token = credentials.token;
    let hash = credentials.hash;
    LoginService.invitedUserRegister(data, token, hash)
      .then((authInfo) => {
        return { ...authInfo };
      })
      .catch((err) => {
        return { ...err };
      });
  },
  doSurveyInvitedRegister({ commit }, credentials) {
    commit("CLEAN_LOCAL_STORAGE");
    localStorage.setItem("userName", credentials.user.firstName);
    commit("SET_AUTHENTICATION_STATUS", false);
    let user = {
      username: credentials.user.email,
      firstName: credentials.user.firstName,
      lastName: credentials.user.lastName,
      clientType: credentials.user.clientType,
      countryCode: credentials.user.countryCode,
      businessParams: credentials.user.businessParams,
      metadata: credentials.user.metadata,
    };
    let responses = [...credentials.responses];
    let data = {
      user,
      responses,
    };
    let token = credentials.token;
    let hash = credentials.user.hash;
    LoginService.invitedSurveyRegister(data, token, hash)
      .then((authInfo) => {
        return { ...authInfo };
      })
      .catch((err) => {
        return { ...err };
      });
  },
  doActivate: function ({ commit }, credentials) {
    commit("CLEAN_LOCAL_STORAGE");
    return new Promise((resolve, reject) => {
      commit("SET_AUTHENTICATION_STATUS", false);
      LoginService.activate(Vue._.get(credentials, "userId"), Vue._.get(credentials, "password")).then((authInfo) => {
        if (Vue._.get(authInfo, "isAxiosError")) {
          commit("SET_AUTHENTICATION_STATUS", false);
          reject();
        }
      });
    });
  },
  validateToken: function ({ commit }, token) {
    return new Promise((resolve) => {
      LoginService.validateToken(token)
        .then(() => {
          commit("SET_AUTHENTICATION_STATUS", true);
          resolve(true);
        })
        .catch(() => resolve(false));
    });
  },
  isAuthenticated: function ({ state }) {
    return new Promise((resolve, reject) => {
      if (!Vue._.get(state, "accessToken")) {
        reject({ message: "Null or Empty token" });
      } else {
        LoginService.validateToken(Vue._.get(state, "accessToken"))
          .then((response) => {
            if (response) {
              resolve(true);
            } else {
              reject({ message: "Invalid token" });
              this.doLogout();
            }
          })
          .catch(() => reject({ message: "Unexpected networking error" }));
      }
    });
  },
  isCorporative: async ({ state, commit }) => {
    const clientId = state.userInfo.principal.clientId;
    const client = await CurrentUserService.currentClient(clientId);
    const { metadata } = client;
    if (metadata.corporative) {
      commit("SET_CORPORATIVE", true);
    } else {
      commit("SET_CORPORATIVE", false);
    }
  },
  async setSidebarContent({ commit }) {
    try {
      const uaaUser = await currentUserService.currentUser();
      const umapiUser = await currentUserService.currentUserData();
      const imo = await dtService.getSurveyComplete("imo-index-v1", umapiUser.id);
      const imtd = await dtService.getSurveyComplete("a123", umapiUser.id);
      const content = [];
      sidebarContentOptions.forEach((element) => {
        if (uaaUser.authorities.find((e) => e.authority == element.authorityRequired)) {
          content.push(element);
        }
      });
      const filterCondition = [];
      imo.answered == 24 ? filterCondition.push("AUTH_IMO_SURVEY") : filterCondition.push("AUTH_IMO_DASHBOARD");
      imtd.answered == 33 ? filterCondition.push("AUTH_IMTD_SURVEY") : filterCondition.push("AUTH_IMTD_DASHBOARD");
      const result = content.filter(
        (e) => e.authorityRequired != filterCondition[0] && e.authorityRequired != filterCondition[1]
      );
      commit("SET_SIDEBAR_CONTENT", result);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  SET_CORPORATIVE(state, bool) {
    state.corporative = bool;
  },
  SET_CLIENT(state, client) {
    state.client = client;
  },
  SET_SIDEBAR_CONTENT(state, content) {
    state.sidebarContent = content;
  },
  SET_AUTHENTICATION_STATUS(state, status) {
    state.authenticated = status;
  },
  SET_AUTH_INFO(state, authInfo) {
    state.authInfo = authInfo;
    localStorage.setItem("authInfo", JSON.stringify(authInfo));
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    localStorage.setItem("userName", userInfo.name);
    localStorage.setItem("userId", userInfo.principal.id);
  },
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken;
    localStorage.setItem("accessToken", accessToken);
  },
  SET_CLIENT_INFO(state, clientInfo) {
    state.clientInfo = clientInfo;

    localStorage.setItem("clientInfo", JSON.stringify(clientInfo));
  },
  LOGOUT(state) {
    state.accessToken = "";
    state.authInfo = {};
    state.userInfo = {
      principal: {},
    };
    (state.userName = ""), (state.accessToken = "");
    state.authenticated = false;

    localStorage.removeItem("authInfo");
    localStorage.removeItem("previus");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("clientInfo");
    localStorage.removeItem("formMixin");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("payment");
    localStorage.removeItem("subscription");
    localStorage.removeItem("answers");
    localStorage.removeItem("percentage");
    localStorage.removeItem("answersImo");
    localStorage.removeItem("percentageImo");
    localStorage.removeItem("authorities");
  },

  CLEAN_LOCAL_STORAGE() {
    localStorage.removeItem("authInfo");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("clientInfo");
    localStorage.removeItem("formMixin");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("payment");
    localStorage.removeItem("subscription");
    localStorage.removeItem("authorities");
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
