import Vue from "vue";
import Vuex from "vuex";

import authentication from "./modules/authentication";
import UserStore from "./modules/UserStore";
import layout from "./modules/layout";
import payment from "./modules/payment";
import subscription from "./modules/subscription";
import typeform from "./modules/typeform";
import createProject from "./modules/create-project";
import applyForProject from "./modules/apply-for-project";
import ranking from "./modules/ranking";
import DigitalTransformationStore from "./modules/DigitalTransformationStore";
import CompanyStore from "./modules/CompanyStore";
import PlanStore from "./modules/PlanStore";
import ImoStore from "./modules/ImoStore";
import ImtdStore from "./modules/ImtdStore";
import InvitationsStore from "./modules/InvitationsStore";
import TrendStore from "./modules/TrendStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication,
    layout,
    payment,
    subscription,
    typeform,
    UserStore,
    createProject,
    applyForProject,
    ranking,
    DigitalTransformationStore,
    CompanyStore,
    ImoStore,
    ImtdStore,
    PlanStore,
    InvitationsStore,
    TrendStore,
  },
  strict: process.env.NODE_ENV !== "production",
});
