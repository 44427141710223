import uaaAxios from "@/utils/authAxios";

export default {
  /**
   *
   * @returns
   */
  getPlans: async () => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_PLANS_API}/plans`);
    return data;
  },
  /**
   *
   * @returns
   */
  getSuscription: async () => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_PLANS_API}/subscriptions`);
    return data;
  },
  /**
   *
   * @param {*} id
   * @returns
   */
  findPlan: async (id) => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_PLANS_API}/plans/${id}`);
    return data;
  },
  /**
   *
   * @param {*} type
   * @returns
   */
  getPlansType: async (type) => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_PLANS_API}/plans/type/${type}`);
    return data;
  },
};
