import subscriptionService from "@/services/subscriptionService";
import planService from "@/services/planService";

const state = {
  subscription: {},
  selectedPlan: {},
  selectedPaymentMethod: "",
  paypalCheckoutData: {},
};

const getters = {
  getSelectedPlan: (state) => state.selectedPlan,
  getSubscription: (state) => state.subscription,
  getSelectedPaymentMethod: (state) => state.selectedPaymentMethod,
  getPaypalCheckoutData: (state) => state.paypalCheckoutData,
};

const actions = {
  async setSubscription({ commit }) {
    try {
      const subscription = await subscriptionService.getCurrentUserSubscription();
      commit("SET_SUBSCRIPTION", subscription);
    } catch (error) {
      console.log(error);
    }
  },
  async setSelectedPlan({ commit }, data) {
    try {
      const plans = await planService.getPlansType(data.companyType);
      const plan = plans.find((e) => e.id == data.planId);
      commit("SET_SELECTED_PLAN", plan);
    } catch (error) {
      console.log(error);
    }
  },
  setSelectedPaymentMethod({ commit }, selectedPaymentMethod) {
    commit("SET_SELECTED_PAYMENT_METHOD", selectedPaymentMethod);
  },
  setPaypalCheckoutData({ commit }, paypalCheckoutData) {
    commit("SET_PAYPAL_CHECKOUT_DATA", paypalCheckoutData);
  },
  SET_SELECTED_PLAN({ commit }, plan) {
    commit("SET_SELECTED_PLAN", plan);
  },
};

const mutations = {
  SET_SUBSCRIPTION(state, payload) {
    state.subscription = payload;
    localStorage.setItem("subscription", payload.id);
  },
  SET_SELECTED_PLAN(state, plan) {
    state.selectedPlan = plan;
  },
  SET_SELECTED_PAYMENT_METHOD(state, selectedPaymentMethod) {
    state.selectedPaymentMethod = selectedPaymentMethod;
  },
  SET_PAYPAL_CHECKOUT_DATA(state, paypalCheckoutData) {
    state.paypalCheckoutData = paypalCheckoutData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
