<template>
  <v-app>
    <router-view class="scroll" id="main" />
  </v-app>
</template>

<style lang="scss" scoped>
.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 20px;
    background-color: rgba(#cccccc, 0.7);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #cccccc;
    border: 4px solid rgba(0, 0, 0, 0);
    cursor: pointer;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
</style>

<script>
export default {
  name: "NoAuthLayout",
  components: {},
};
</script>
