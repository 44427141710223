import DtService from "../../services/dtService";

const ImoStore = {
  namespaced: true,
  state: {
    percentage: parseInt(localStorage.getItem("percentageImo")),
    userId: "",
    surveyId: "",
    surveyCode: "imo-index-v1",
    surveyProgress: 0,
    answeredQuestions: [],
    surveyQuestions: [],
    surveyAnswers: [],
    dimensionAdvices: {},
    generalAdvice: "",
    generalComment: "",
    indexInfo: {},
  },
  getters: {
    surveyProgress: (state) => state.surveyProgress || 0,
    surveyQuestions: (state) => state.surveyQuestions || [],
    getPercentage: (state) => state.percentage,
    getDimensionAdvices: (state) => state.dimensionAdvices || {},
    getGeneralAdvice: (state) => state.generalAdvice || "",
    getGeneralComment: (state) => state.generalComment || "",
    getIndexInfo: (state) => state.indexInfo || {},
  },
  actions: {
    init({ commit }) {
      commit("INITIALIZE_SURVEY_STORE");
    },
    async getSurvey({ commit, state }) {
      let survey = await DtService.getSurvey(state.surveyCode);
      let surveyId = survey.content[0].id;
      let questions = survey.content[0].questions;
      let clientInfo = JSON.parse(localStorage.getItem("clientInfo"));
      let clientId = clientInfo.id;
      let userId = localStorage.getItem("userId");
      const advance = await DtService.getSurveyComplete(state.surveyCode, userId);
      let answeredQuestions = advance.answeredQuestions;
      let answersLength = advance.answered;
      let total = advance.total;
      let progress = Math.floor((answersLength / total) * 100);
      questions.reverse();
      commit("SET_SURVEY", {
        questions,
        surveyId,
        clientId,
        userId,
        answeredQuestions,
        progress,
      });
    },
    setPercentage({ commit }, percentage) {
      commit("SET_PERCENTAGE", percentage);
    },
    setAnswers({ commit }, answers) {
      commit("CLEAN_LOCAL_STORAGE_ANSWERS");
      commit("SET_ANSWERS", answers);
    },

    getRecomendations({ commit }, filter) {
      return new Promise((resolve, reject) => {
        let clientInfo = JSON.parse(localStorage.getItem("userInfo"));
        let clientId = clientInfo.principal.clientId;
        let surveyCode = "imo-index-v1";
        DtService.getRecomendationsByClient(clientId, surveyCode, "", "", filter)
          .then((recomendations) => {
            commit("SET_RECOMENDATIONS", recomendations);
            resolve();
          })
          .catch(() => {
            reject("error");
          });
      });
    },
  },
  mutations: {
    SET_RECOMENDATIONS(state, recomendations) {
      state.dimensionAdvices = recomendations.dimensionAdvices;
      state.generalAdvice = recomendations.generalAdvice;
      state.generalComment = recomendations.generalComment;
      state.indexInfo = recomendations.indexInfo;
    },
    SET_PERCENTAGE(state, percentage) {
      state.percentage = percentage;
      localStorage.setItem("percentageImo", JSON.stringify(percentage));
    },
    SET_ANSWERS(state, answers) {
      state.answers = answers;
      localStorage.setItem("answersImo", JSON.stringify(answers));
    },
    CLEAN_LOCAL_STORAGE_ANSWERS() {
      localStorage.removeItem("answersImo");
    },
    INITIALIZE_SURVEY_STORE(state) {
      state.userId = "";
      state.surveyId = "";
      state.surveyAnswers = [];
      state.surveyQuestions = [];
      state.dimensionAdvices = {};
      state.generalAdvice = "";
      state.generalComment = "";
      state.indexInfo = {};
    },
    ADD_ANSWERS(state, answers) {
      state.surveyAnswers = [...state.surveyAnswers, ...answers];
    },
    SET_SURVEY(state, { questions, surveyId, clientId, userId, answeredQuestions, progress }) {
      state.surveyQuestions = questions;
      state.surveyId = surveyId;
      state.clientId = clientId;
      state.userId = userId;
      state.answeredQuestions = answeredQuestions;
      state.surveyProgress = progress;
    },
  },
};

export default ImoStore;
