import uaaAxios from "@/utils/authAxios";
import axios from "axios";

const invitationService = {
  addInvitation: async (data) => {
    try {
      const responseData = await uaaAxios.post(`${process.env.VUE_APP_INVITATION_API}/invitations`, data);
      return { ...responseData, status: "ok" };
    } catch (error) {
      return { ...error };
    }
  },
  getInvitations: async (clientId) => {
    try {
      const response = await uaaAxios.get(`${process.env.VUE_APP_INVITATION_API}/invitations/${clientId}`);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getInvitation: async (token, hash) => {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL_BASE}${process.env.VUE_APP_INVITATION_API}/invitations/hash/${hash}?recaptchaResponse=${token}`
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  deleteInvitation: async (invitationId) => {
    try {
      const responseData = await uaaAxios.delete(`${process.env.VUE_APP_INVITATION_API}/invitations/${invitationId}`);
      return responseData;
    } catch (error) {
      return error;
    }
  },
  reSendInvitation: async (invitationId) => {
    try {
      const responseData = await uaaAxios.put(
        `${process.env.VUE_APP_INVITATION_API}/invitations/notifications/${invitationId}`
      );
      return responseData;
    } catch (error) {
      return error;
    }
  },
};

export default invitationService;
