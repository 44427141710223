<template>
  <div>
    <component :is="layout" />
  </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import NoAuthLayout from "@/layouts/NoAuthLayout";

export default {
  name: "App",
  components: {
    AppLayout,
    NoAuthLayout,
  },
  computed: {
    layout() {
      return this.$store.getters["layout/name"];
    },
  },
};
</script>
