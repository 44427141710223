import Vue from "vue";
import uaaAxios from "@/utils/authAxios";
import axios from "axios";

export default {
  /* eslint-disable */
  getCompanyProfile: function () {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }

    return new Promise((resolve, reject) => {
      if (clientId) {
        uaaAxios
          .get(`${process.env.VUE_APP_COMPANIES_API}/companies/${clientId}`)
          .then((response) => {
            let data = response.data;
            if (data.size == null) {
              data["emptyProfile"] = true;
            }
            resolve(data);
          })
          .catch((err) => reject(err));
      } else {
        resolve();
      }
    });
  },
  getSpecialists: async function () {
    try {
      const { data } = await uaaAxios.get(
        `${process.env.VUE_APP_COMPANIES_API}/specialists`
      );
      return data;
    } catch (error) {
      console.error(error);
    }

    uaaAxios.get(`${process.env.VUE_APP_COMPANIES_API}/companies/${clientId}`);
  },

  getCompanyProfilePublic: function (id) {
    return new Promise((resolve, reject) => {
      if (id) {
        uaaAxios
          .get(`${process.env.VUE_APP_COMPANIES_API}/specialists/find/${id}`)
          .then((response) => {
            let data = Vue._.get(response, "data");
            resolve(data);
          })
          .catch((err) => reject(err));
      } else {
        resolve();
      }
    });
  },

  getCompanyPublicInfo: function (id) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(
          `${process.env.VUE_APP_COMPANIES_API}/companies/project-postulation/${id}`
        )
        .then((response) => {
          let data = Vue._.get(response, "data");
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  updateCompanyProfile: function (data) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = userInfo.principal.clientId;

    return new Promise((resolve, reject) => {
      this.getCompanyProfile()
        .then((company) => {
          for (const prop in data) {
            company[prop] = data[prop];
          }
          let clientType =
            company.type === "CLIENT" ? "clients" : "specialists";

          uaaAxios
            .put(
              `${process.env.VUE_APP_COMPANIES_API}/${clientType}/${clientId}`,
              company
            )
            .then((response) => resolve(Vue._.get(response, "data")))
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  },

  getCategories: function (userId, withSpecialistsAmount) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(
          `${process.env.VUE_APP_COMPANIES_API}/categories?clientId=${userId}&withSpecialistsAmount=${withSpecialistsAmount}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },

  getServices: function (clientId, withSpecialistsAmount) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(
          `${process.env.VUE_APP_COMPANIES_API}/categories/services?clientId=${clientId}&withSpecialistsAmount=${withSpecialistsAmount}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },

  getDimensions: function (userId) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(
          `${process.env.VUE_APP_COMPANIES_API}/dimensions?clientId=${userId}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },

  getSectors: function (userId) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${process.env.VUE_APP_COMPANIES_API}/sectors?clientId=${userId}`)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },

  getOdometer: async (specialistId) => {
    try {
      return await uaaAxios.get(
        `${process.env.VUE_APP_COMPANIES_API}/specialists/odometer/${specialistId}`
      );
    } catch (error) {
      console.log(error);
    }
  },

  getSizes: async (userId) => {
    try {
      return await uaaAxios.get(
        `${process.env.VUE_APP_COMPANIES_API}/sizes?clientId=${userId}`
      );
    } catch (error) {
      console.log(error);
    }
  },

  getRankingYears: function () {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }
    return new Promise((resolve, reject) => {
      if (clientId) {
        uaaAxios
          .get(
            `${process.env.VUE_APP_COMPANIES_API}/specialists/trajectory/${clientId}`
          )
          .then((response) => {
            resolve(Vue._.get(response, "data"));
          })
          .catch((err) => reject(err));
      } else {
        resolve();
      }
    });
  },
  getRankingYearsClient: async (clientId) => {
    try {
      return await uaaAxios.get(
        `${process.env.VUE_APP_COMPANIES_API}/clients/trajectory/${clientId}`
      );
    } catch (error) {
      console.log(error);
    }
  },

  getRankingSize: async (clientId) => {
    try {
      return await uaaAxios.get(
        `${process.env.VUE_APP_COMPANIES_API}/specialists/size-index?specialistId=${clientId}`
      );
    } catch (error) {
      console.log(error);
    }
  },

  getCompanyName: function (tributaryId) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/companies/company-name?tributaryId=${tributaryId}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  getCompanyBusinessArea: function (token) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/business-area?recaptchaResponse=${token}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  getCompanyRegion: function (countryId, token) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/region?country-id=${countryId}&recaptchaResponse=${token}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  getCompanyAllRegions: function (token) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/region?recaptchaResponse=${token}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  getCompanyWorkplaceSeniority: function (token) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/workplace-seniority?recaptchaResponse=${token}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  getCompanyAgeRange: function (token) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/age-range?recaptchaResponse=${token}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  getCompanyGender: function (token) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/gender?recaptchaResponse=${token}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  getCompanyManagements: function (token) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_COMPANIES_API
          }/managements?&recaptchaResponse=${token}`
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
};
