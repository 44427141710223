import uaaAxios from "@/utils/authAxios";

const TrendService = {
  /**
   *
   * @returns
   */
  getTrends: async () => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_TRENDS_API}/trends`);
    return data;
  },
  /**
   *
   * @returns
   */
  getGATrends: async (language = "es") => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_TRENDS_API}/trends/getabstract?language=${language}`, {
      timeout: 6000, // Set the timeout to 10 seconds (10000 milliseconds)
    });
    return data;
  },
  /**
   *
   * @param {*} id
   * @returns
   */
  getTrend: async (id) => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_TRENDS_API}/trends/${id}`);
    return data;
  },
};

export default TrendService;
