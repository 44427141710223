<template>
  <div />
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.tway-footer {
  padding-top: 50px !important;
  padding-bottom: 44px !important;
  color: #ffffff;
}

.tway-footer a {
  text-decoration: none !important;
}
</style>
