import AbstractError from "../AbstractError";

class CallForTenderError extends AbstractError {
  /**
   *
   * @param {Object} error
   */
  constructor(error) {
    super(error.message, error.errorCode);
    this.url = error.url;
    this.name = "Call For Tender Error";
  }
}

export default CallForTenderError;
