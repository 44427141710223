import CurrentUserService from "@/services/currentUserService";
import CompanyService from "@/services/companyService";
import PlanService from "@/services/planService";
import LoginService from "@/services/loginService";
import Vue from "vue";

const UserStore = {
  namespaced: true,
  getters: {
    getUmapiUser: (state) => state.umapiUser,
    getUaaUser: (state) => state.uaaUser,
    getClientId: (state) => state.uaaUser.principal.clientId,
    getUserId: (state) => state.uaaUser.principal.id,
    getUserAuthorities: (state) => state.uaaUser.authorities,
    getCompany: (state) => state.company,
    getSuscription: (state) => state.suscription,
    getPercentage: (state) => state.percentage,
    getAnswers: (state) => state.answers,
    getImtd: (state) => state.imtd,
    getSteps: (state) => state.steps,
  },
  state: {
    umapiUser: {},
    uaaUser: {},
    company: {},

    suscription: {
      plan: {
        name: "",
      },
    },
    percentage: localStorage.getItem("percentage") || 0,
    answers: JSON.parse(localStorage.getItem("answers")) || {},
    steps: [
      {
        index: 0,
        title: "Realiza la encuesta de Transformación Digital",
        redirectTo: { name: "dti-form" },
        active: false,
      },
      {
        index: 1,
        title: "Completa tu perfil al 100%",
        redirectTo: { name: "my-profile" },
        active: false,
      },
      {
        index: 3,
        title: "Conoce los contenidos y tendencias más importantes sobre Transformación",
        redirectTo: { name: "trends" },
        active: false,
      },
    ],
    imtd: null,
  },

  actions: {
    async setUaaUserData({ commit }) {
      try {
        const response = await CurrentUserService.currentUser();
        commit("SET_UAA_USER", response);
      } catch (error) {
        console.log(error);
      }
    },
    async setUmapiUserData({ commit }) {
      try {
        const response = await CurrentUserService.currentUserData();
        commit("SET_UMAPI_USER", response);
      } catch (error) {
        console.log(error);
      }
    },
    async setCompanyProfile({ commit }) {
      await CompanyService.getCompanyProfile().then((response) => {
        commit("SET_COMPANY_DATA", response);
      });
    },

    SET_PERCENTAGE({ commit }, percentage) {
      commit("CLEAN_LOCAL_STORAGE_PERCENTAGE");
      commit("SET_PERCENTAGE", percentage);
    },
    SET_IMTD({ commit }, imtd) {
      commit("SET_IMTD", imtd);
    },
    SET_ANSWERS({ commit }, answers) {
      commit("CLEAN_LOCAL_STORAGE_ANSWERS");
      commit("SET_ANSWERS", answers);
    },
    SET_STEP_ACTIVE({ commit }, index) {
      commit("SET_STEP_ACTIVE", index);
    },
    SET_STEP_INACTIVE({ commit }, index) {
      commit("SET_STEP_INACTIVE", index);
    },
    FETCH_USER({ commit }, state) {
      commit("CLEAN_LOCAL_STORAGE");
      new Promise((resolve, reject) => {
        if (!Vue._.get(state, "accessToken")) {
          console.log("no auth");
          resolve("No auth");
        } else {
          LoginService.validateToken(Vue._.get(state, "accessToken"))
            .then((response) => {
              if (response) {
                new Promise((resolve, reject) => {
                  CurrentUserService.currentUserData()
                    .then((response) => {
                      commit("SET_USER", response);
                      resolve(response);
                    })
                    .catch((error) => reject(error));
                });
                new Promise((resolve, reject) => {
                  PlanService.getSuscription()
                    .then((response) => {
                      commit("SET_SUSCRIPTION", response);
                      resolve(response);
                    })
                    .catch((error) => reject(error));
                });
              } else {
                reject({ message: "Invalid token" });
              }
            })
            .catch(() => reject({ message: "Unexpected networking error" }));
        }
      });
    },
  },
  mutations: {
    SET_COMPANY_DATA(state, data) {
      state.company = data;
    },

    SET_UMAPI_USER(state, user) {
      state.umapiUser = user;
    },
    SET_UAA_USER(state, user) {
      state.uaaUser = user;
    },
    SET_PERCENTAGE(state, percentage) {
      state.percentage = percentage;
      localStorage.setItem("percentage", JSON.stringify(percentage));
    },
    SET_ANSWERS(state, answers) {
      state.answers = answers;
      localStorage.setItem("answers", JSON.stringify(answers));
    },
    SET_SUSCRIPTION(state, suscription) {
      state.suscription = suscription;
      localStorage.setItem("suscription", JSON.stringify(suscription));
    },
    CLEAN_LOCAL_STORAGE_PERCENTAGE() {
      localStorage.removeItem("percentage");
    },
    CLEAN_LOCAL_STORAGE_ANSWERS() {
      localStorage.removeItem("answers");
    },
    CLEAN_LOCAL_STORAGE() {
      localStorage.removeItem("user");
      localStorage.removeItem("suscription");
    },
    SET_IMTD(state, imtd) {
      state.imtd = imtd;
    },
    SET_STEP_ACTIVE(state, index) {
      state.steps[index].active = true;
    },
    SET_STEP_INACTIVE(state, index) {
      state.steps[index].active = false;
    },
  },
};

export default UserStore;
