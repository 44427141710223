<template>
  <div>
    <v-navigation-drawer
      color="tway-violet-dark"
      :mini-variant="drawer"
      v-if="desktop"
      app
      disable-resize-watcher
      :style="drawer && desktop ? 'width: 100px !important;' : ''"
    >
      <v-list-item class="px-2">
        <v-row fluid class="ma-0 py-5" justify="center">
          <v-img v-if="!drawer" :src="require('@/assets/img/Tway-logotipo-gris.png')" contain max-width="100px" />
          <v-img v-if="drawer" :src="require('@/assets/img/Tway-logotipo-gris-small.svg')" contain max-width="41px" />
        </v-row>
      </v-list-item>

      <Loader :visible="loading" />
      <v-list dense v-if="!loading">
        <v-list-item
          @click="$router.push({ name: item.go, params: item.params })"
          v-for="item in links"
          :key="item.title"
          link
          :id="'navbar-desktop-link-' + item.id"
          :class="($route.name == item.go ? 'leftbar-selected' : '') + ' link-class'"
        >
          <v-list-item-content>
            <v-list-item-title class="white--text nav-link-container">
              <div v-if="item.go == 'my-profile' || item.go == 'my-profile-new'">
                <v-img :src="require('@/assets/img/icons/menu/perfil.svg')" />
              </div>
              <div v-if="item.go == 'home'">
                <v-img :src="require('@/assets/img/icons/menu/home.svg')" />
              </div>
              <div v-if="item.go == 'inviteds'">
                <v-img :src="require('@/assets/img/icons/menu/invited.png')" />
              </div>
              <div v-if="item.go == 'plans'">
                <v-img :src="require('@/assets/img/icons/menu/plans.svg')" />
              </div>
              <div v-if="item.go == 'trends'">
                <v-img :src="require('@/assets/img/icons/menu/trends.svg')" />
              </div>
              <div v-if="item.go == 'dti-form'">
                <v-img :src="require('@/assets/img/icons/menu/dti.svg')" />
              </div>

              <div v-if="item.go == 'survey-imo'">
                <v-img :src="require('@/assets/img/icons/menu/dti.svg')" />
              </div>

              <div v-if="item.go == 'briefcase'">
                <v-img :src="require('@/assets/img/icons/menu/briefcase.svg')" />
              </div>
              <div v-if="item.go == 'dashboard' || item.go == 'dashboardNew'">
                <v-img :src="require('@/assets/img/icons/menu/dashboard.svg')" />
              </div>
              <div v-if="item.go == 'dashboard-imo'">
                <v-img :src="require('@/assets/img/imo/dashboard-imo.png')" />
              </div>
              <div v-if="item.go == 'my-projects'">
                <v-img :src="require('@/assets/img/icons/menu/rocket.svg')" />
              </div>
              <div v-if="item.go == 'dashboard-bsc'">
                <v-img :src="require('@/assets/img/icons/menu/dashboard.svg')" />
              </div>
              <div v-if="item.go == 'ranking'">
                <v-img :src="require('@/assets/img/icons/menu/ranking.svg')" />
              </div>
              <strong
                v-if="!drawer"
                :style="$route.name != item.go ? 'color: #CCCCCC !important; font-size:16px;' : ' font-size:16px;'"
                >{{ item.title }}</strong
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <v-divider
        class="mt-3 mb-4"
        style="border: 1px solid rgba(204, 204, 204, 0.5)"
      ></v-divider>

      <v-list-item
        @click="$router.push({ name: 'settings' })"
        link
        :class="
          ($route.name == 'settings' ? 'leftbar-selected' : '') + ' link-class'
        "
      >
        <v-list-item-content>
          <v-list-item-title class="white--text nav-link-container">
            <div class="">
              <v-img :src="require('@/assets/img/icons/menu/settings.png')" />
            </div>
            <strong
              v-if="!drawer"
              :style="
                $route.name != 'settings'
                  ? 'color: #CCCCCC !important; font-size:16px;'
                  : ' font-size:16px;'
              "
              >Configuración</strong
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <template v-if="loggedIn" v-slot:append>
        <div class="pa-2 pb-8" :class="drawer ? '' : 'px-10'">
          <v-btn
            :color="drawer ? 'tway-violet-dark' : 'white'"
            @click.stop="LogoutConfirm = true"
            class="button-text elevation-0"
            block
            rounded
            :outlined="!drawer"
            :id="logout - desktop"
          >
            <span v-if="!drawer">Cerrar Sesión</span>
            <v-img max-width="26px" v-if="drawer" :src="require('@/assets/img/icons/logout.svg')" />
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="drawer" color="tway-violet-dark" v-if="!desktop" app disable-resize-watcher>
      <v-list-item class="px-2">
        <v-row fluid class="ma-0 py-5" justify="center">
          <v-img :src="require('@/assets/img/Tway-logotipo-gris.png')" contain max-width="100px" />
        </v-row>
      </v-list-item>

      <v-list dense>
        <v-list-item
          @click="$router.push({ name: item.go })"
          v-for="item in links"
          :key="item.title"
          link
          :id="'navbar-mobile-link-' + item.id"
          :class="$route.name == item.go ? 'leftbar-selected' : ''"
        >
          <v-list-item-content>
            <v-list-item-title class="white--text nav-link-container">
              <div v-if="item.go == 'my-profile' || item.go == 'my-profile-new'">
                <v-img :src="require('@/assets/img/icons/menu/perfil.svg')" />
              </div>
              <div v-if="item.go == 'home'">
                <v-img :src="require('@/assets/img/icons/menu/home.svg')" />
              </div>
              <div v-if="item.go == 'inviteds'">
                <v-img :src="require('@/assets/img/icons/menu/invited.png')" />
              </div>
              <div v-if="item.go == 'plans'">
                <v-img :src="require('@/assets/img/icons/menu/plans.svg')" />
              </div>
              <div v-if="item.go == 'trends'">
                <v-img :src="require('@/assets/img/icons/menu/trends.svg')" />
              </div>
              <div v-if="item.go == 'dti-form'">
                <v-img :src="require('@/assets/img/icons/menu/dti.svg')" />
              </div>
              <div v-if="item.go == 'survey-imo'">
                <v-img :src="require('@/assets/img/icons/menu/dti.svg')" />
              </div>
              <div v-if="item.go == 'briefcase'">
                <v-img :src="require('@/assets/img/icons/menu/briefcase.svg')" />
              </div>
              <div v-if="item.go == 'dashboard' || item.go == 'dashboardNew'">
                <v-img :src="require('@/assets/img/icons/menu/dashboard.svg')" />
              </div>
              <div v-if="item.go == 'dashboard-imo'">
                <v-img :src="require('@/assets/img/imo/dashboard-imo.png')" />
              </div>
              <div v-if="item.go == 'my-projects'">
                <v-img :src="require('@/assets/img/icons/menu/rocket.svg')" />
              </div>
              <div v-if="item.go == 'dashboard-bsc'">
                <v-img :src="require('@/assets/img/icons/menu/dashboard.svg')" />
              </div>
              <div v-if="item.go == 'ranking'">
                <v-img :src="require('@/assets/img/icons/menu/ranking.svg')" />
              </div>
              <strong>{{ item.title }}</strong>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-if="loggedIn" v-slot:append>
        <div class="pa-2 pb-8 px-10">
          <v-btn
            color="white"
            @click.stop="LogoutConfirm = true"
            class="elevation-0"
            block
            rounded
            outlined
            id="logout-mobile"
          >
            <span>Cerrar Sesión</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="LogoutConfirm" max-width="500">
      <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
        <v-card-title
          class="headline mb-3"
          :class="!desktop ? 'pt-8' : ''"
          :style="!desktop ? 'font-size: 20px !important;' : ''"
        >
          <v-row class="ma-0 pa-0" justify="center">
            <span>¿Realmente quieres salir?</span>
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
          <v-row class="ma-0 pa-0 text-center" justify="center">
            <span> Si sales de Tway, te redireccionaremos al inicio de sesión. </span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <a
            style="font-size: 14px"
            class="button-text tway-violet--text roboto"
            v-if="desktop"
            :class="desktop ? 'mr-16' : 'mt-2 mb-4'"
            @click.stop="LogoutConfirm = false"
          >
            CANCELAR
          </a>
          <v-btn v-if="desktop" rounded color="button-text tway-violet" class="cerrar-desk white--text" @click="logout">
            Sí, Cerrar Sesion
          </v-btn>
          <v-row v-if="!desktop" class="ma-0 pa-0" justify="center">
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn rounded color="tway-violet" class="button-text white--text" @click="logout">
                  Sí, Volver al inicio
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <a
                  class="button-text tway-violet--text"
                  v-if="!desktop"
                  :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                  @click.stop="LogoutConfirm = false"
                >
                  CANCELAR
                </a>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar
      app
      elevation="0"
      color="tway-gray"
      class="px-4"
      :style="drawer && desktop ? 'padding-left: 50px !important; padding-right: 50px !important' : ''"
    >
      <v-app-bar-nav-icon color="#666666" @click.stop="drawer = !drawer" />
      <v-spacer />

      <v-menu offset-y style="z-index:auto; !important" v-model="showHelpModal">
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            width="24px"
            height="24px"
            style="cursor: pointer"
            class="mr-4"
            color="#6739b6"
            @click="handleShowHelpModal()"
          >
            <v-img :src="require('@/assets/img/icons/carbon_help.svg')" />
          </v-btn>
        </template>
      </v-menu>
      <v-dialog v-model="showHelpModal" max-width="600">
        <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 20px !important">
          <v-card-title
            class="headline mb-3"
            :class="!desktop ? 'pt-8' : ''"
            :style="!desktop ? 'font-size: 20px !important;' : ''"
          >
            <v-row class="ma-0 pa-0" justify="center">
              <span>Contáctanos</span>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col align="center" cols="12">
                <v-img :src="require('@/assets/img/chatbot.png')" max-width="280px" max-height="196px" />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col align="center" style="font-family: 'Roboto Condensed'">
                <h3 class="mb-2">¿Tienes dudas o necesitas ayuda?</h3>
                <div>
                  Escríbenos a
                  <a href="mailto:soporte@twaydigital.com" style="color: #7319d5">soporte@twaydigital.com</a>
                  y uno de nuestros profesionales del equipo de soporte revisará tu requerimiento y te contactará.
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
              <v-col cols="12" align="center">
                <v-btn
                  v-if="desktop"
                  rounded
                  color="tway-violet"
                  style="
                    font-family: 'Roboto Condensed';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #ffffff;
                  "
                  @click="showHelpModal = false"
                >
                  ACEPTAR
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-tooltip bottom max-width="12em" :value="showHelpTooltip">
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            width="24px"
            height="24px"
            style="cursor: pointer"
            class="mr-4"
            color="#6739b6"
            @click="company.firstLogin = true"
          >
            <v-img :src="require('@/assets/img/icons/carbon_information.svg')" />
          </v-btn>
        </template>

        <span class="text-center">Aquí puedes mirar de nuevo lo que puedes hacer en Tway</span>
      </v-tooltip>

      <v-menu offset-y style="z-index: 15 !important">
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            width="24px"
            @click="
              () => {
                newNotification = false;
              }
            "
            height="24px"
          >
            <v-img
              :src="
                newNotification
                  ? require('@/assets/img/icons/carbon_notification-new.svg')
                  : require('@/assets/img/icons/carbon_notification.svg')
              "
            />
          </v-btn>
        </template>
        <v-card width="100%" class="elevation-0 pa-0 border-card-bottom">
          <v-row class="ma-0 pa-0">
            <v-col
              class="ma-0 px-6 pt-4"
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              justify="start"
              align="start"
              style="color: #333333"
            >
              <strong>Notificaciones</strong>
            </v-col>
          </v-row>
        </v-card>
        <v-list class="max-scroll overflow-y-auto" style="max-width: 440px">
          <v-container
            fluid
            style="height: 100%; display: flex; align-items: center; flex-direction: column; justify-content: center"
            v-if="notifications.length == 0"
          >
            <v-row
              :style="mobile ? 'width: 100%' : 'width: 440px !important;'"
              justify="center"
              class="px-10 py-0 ma-0"
            >
              <v-img :src="require('@/assets/img/notifications/empty.svg')" style="pa-0 ma-0" max-width="50%" contain />
            </v-row>
            <v-row
              :style="mobile ? 'width: 100%' : 'width: 440px !important;'"
              justify="center"
              class="px-10 px-0 ma-0"
            >
              <span class="notification-empty">No hay notificaciones en este momento</span>
            </v-row>
          </v-container>
          <v-container
            fluid
            class="pa-0 ma-0 border-card-bottom"
            v-for="(notification, notificationIndex) in notifications"
            :key="notificationIndex"
          >
            <v-list-item
              :link="false"
              @click="
                () => {
                  goTo(notification.url);
                }
              "
              style="border-radius: 0px; overflow: hidden"
              class="full-card elevation-0 pa-0"
            >
              <v-row align="stretch" class="ma-0 pa-0">
                <v-col style="height: 100%" class="ma-0 pt-4 pr-0" cols="2" justify="center" align="center">
                  <v-img
                    v-if="types.find((e) => e == notification.type.toLowerCase())"
                    :src="require('@/assets/img/notifications/' + notification.type.toLowerCase() + '.svg')"
                    width="40px"
                    style="pa-0 ma-0"
                    contain
                  />
                </v-col>
                <v-col class="ma-0" cols="10" justify="start" align="start">
                  <div class="py-0">
                    <v-row class="pa-0 ma-0" justify="space-between" align="center">
                      <v-col class="pa-0 ma-0" align-self="start">
                        <small
                          ><span class="roboto" style="color: #717171; max-width: 300px">{{
                            notification.content
                          }}</span></small
                        >
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="2" align-self="start">
                        <span class="roboto">
                          <v-row class="ma-0 pa-0" justify="end">
                            <div class="pr-2" style="display: flex; align-items: center">
                              <v-img
                                :src="require('@/assets/img/icons/close.svg')"
                                width="15px"
                                style="pa-0 ma-0"
                                @click="deleteNotification(notification)"
                                contain
                              />
                            </div>
                            <div style="width: 18px; display: flex; align-items: center">
                              <v-img
                                :src="
                                  notification.readed
                                    ? require('@/assets/img/icons/read.svg')
                                    : require('@/assets/img/icons/notread.svg')
                                "
                                style="pa-0 ma-0"
                                @click="changeNotification(notification)"
                                contain
                              />
                            </div>
                          </v-row>
                        </span>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="roboto" style="max-width: 300px; color: #999999; max-height: 100px; overflow: hidden">
                    <small
                      ><span>{{ timeSince(notification.date) }}</span></small
                    >
                  </div>
                </v-col>
              </v-row>
            </v-list-item>
          </v-container>
        </v-list>
      </v-menu>
    </v-app-bar>
    <onboarding-modal v-if="company" :show="company.firstLogin" :type="clientType" @close="checkFirstLogin" />
  </div>
</template>

<style lang="scss" scoped>
.leftbar-selected {
  border-left: 3px solid #dde2ff !important;
  background-color: rgba(#9fa2b4, 0.1) !important;
}

.row-list {
  display: flex;
  flex-direction: column;
  background: #fff !important;
}

.max-scroll {
  height: 500px;
}

.nav-link-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > div {
    display: flex;
    justify-content: center;
    padding-left: 18px;
    padding-right: 18px;
    width: 60px;
    .v-responsive {
      width: 40% !important;
    }
  }
}

.border-card-bottom {
  border-bottom: 1px solid #eceff1 !important;
}

.notification-empty {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #999999;
}

.link-class {
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
}

.full-card {
  width: 100% !important;
  border-radius: 0px;
  // border-radius: 15px !important;
}
.cerrar-desk {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  margin-right: 30px;

  /* Tway / Violeta */

  background: #7319d5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 30px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import CompanyService from "@/services/companyService";
import AlertService from "@/services/alertService";
import OnboardingModal from "@/components/onboarding/OnboardingModal.vue";
import Loader from "@/components/Loader";

export default {
  name: "AppHeader",
  components: {
    OnboardingModal,
    Loader,
  },
  props: {
    appName: {
      type: String,
      default: "",
    },
    showModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      drawer: null,
      loggedIn: true,
      LogoutConfirm: false,
      newNotification: false,
      notifications: [],
      types: ["general", "imtd", "plans", "portfolio", "profile", "security", "survey", "trends"],
      items: [],
      clientType: "",
      company: null,
      showHelpTooltip: false,
      showHelpModal: false,
      loading: true,
    };
  },
  created() {
    this.getCompany();
    if (this.isAuth) {
      AlertService.getNotifications()
        .then((response) => {
          this.notifications = response;
        })
        .catch((err) => this.$log(err));
      setInterval(() => {
        if (this.isAuth) {
          AlertService.getNotifications()
            .then((response) => {
              if (response.length > this.notifications.length) {
                this.newNotification = true;
              }
              this.notifications = response;
            })
            .catch((err) => this.$log(err));
        }
      }, 30000);
    }
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    handleShowHelpModal() {
      this.showHelpModal = true;
    },
    timeSince(date) {
      var timeStamp = Date.parse(date);
      var now = new Date(),
        secondsPast = (now.getTime() - timeStamp) / 1000;
      if (secondsPast < 60) {
        return "Hace " + parseInt(secondsPast) + (parseInt(secondsPast) == 1 ? " segundo !" : " segundos !");
      }
      if (secondsPast < 3600) {
        return "Hace " + parseInt(secondsPast / 60) + (parseInt(secondsPast / 60) == 1 ? " minuto !" : " minutos !");
      }
      if (secondsPast <= 86400) {
        return "Hace " + parseInt(secondsPast / 3600) + (parseInt(secondsPast / 3600) == 1 ? " hora !" : " horas !");
      }
      if (secondsPast > 86400) {
        var day = new Date(timeStamp).getDate();
        var month = new Date(timeStamp).toLocaleDateString("es-ES", {
          month: "short",
        });
        var year =
          new Date(timeStamp).getFullYear() == now.getFullYear() ? "" : " " + new Date(timeStamp).getFullYear();
        return day + " " + month + year;
      }
    },
    goTo(url) {
      if (url) {
        window.location.href = url;
      }
    },
    changeNotification(noti) {
      AlertService.readNotification(noti.id)
        .then(() => {
          this.notifications = this.notifications.map((n) => {
            if (n.id == noti.id) {
              n.readed = !n.readed;
            }
            return n;
          });
        })
        .catch((err) => this.$log(err));
    },
    deleteNotification(noti) {
      AlertService.deleteNotification(noti.id)
        .then(() => {
          this.notifications = this.notifications.filter((n) => n.id != noti.id);
        })
        .catch((err) => this.$log(err));
    },
    ...mapActions("authentication", ["doLogout", "setSidebarContent"]),
    logout() {
      this.doLogout()
        .then(() => this.$router.push({ name: "login" }))
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    getCompany() {
      CompanyService.getCompanyProfile()
        .then((company) => {
          this.clientType = this._.get(company, "type");
          this.company = company;
        })
        .catch((err) => this.$log(err));
    },
    checkFirstLogin() {
      this.company.firstLogin = false;
      this.showHelpTooltip = true;
      let companyPOST = {
        ...this.company,
        industrialSector: this.company.industrialSector ? this.company.industrialSector.id : null,
        industrialSectors: this.company.industrialSectors ? this.company.industrialSectors.map((is) => is.id) : null,
        dimensions: this.company.dimensions ? this.company.dimensions.map((d) => d.name) : null,
      };
      CompanyService.updateCompanyProfile(companyPOST).catch((err) => this.$log.error(err));
      setTimeout(() => {
        this.showHelpTooltip = false;
      }, 6000);
    },
  },
  computed: {
    ...mapGetters("authentication", ["isAuth", "Auth", "getSidebarContent"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    links() {
      return this.clientType == "CLIENT"
        ? this.items.filter((i) => i.type == "CLIENT" || i.type == "BOTH")
        : this.items.filter((i) => i.type == "SPECIALIST" || i.type == "BOTH");
    },
  },
  watch: {
    desktop() {
      if (this.desktop) {
        this.drawer = null;
      }
    },
    getSidebarContent() {
      this.items = this.getSidebarContent;
    },
    loading() {
      this.setSidebarContent();
      this.items = this.getSidebarContent;
    },
    drawer() {
      this.$emit("update", this.drawer);
    },
    showModal() {
      if (this.showModal) {
        this.LogoutConfirm = true;
      }
    },
  },
};
</script>
