import uaaAxios from "@/utils/authAxios";

const AlertService = {
  /**
   *
   * @returns
   */
  getNotifications: async () => {
    const { data } = await uaaAxios.get(`${process.env.VUE_APP_ALERT_API}/alerts`);
    return data;
  },

  /**
   *
   * @param {*} id
   * @returns
   */
  deleteNotification: async (id) => {
    const { data } = await uaaAxios.delete(`${process.env.VUE_APP_ALERT_API}/alerts?alertId=${id}`);
    return data;
  },

  /**
   *
   * @param {*} id
   * @returns
   */
  readNotification: async (id) => {
    const { data } = await uaaAxios.put(`${process.env.VUE_APP_ALERT_API}/alerts?alertId=${id}`);
    return data;
  },
};

export default AlertService;
