<template>
  <div>
    <v-dialog v-model="show" max-width="37.5rem" persistent v-if="firstModal">
      <v-card class="onboarding-modal">
        <v-card-title class="d-block">
          <div class="d-flex justify-end">
            <v-icon color="tway-violet" style="cursor: pointer" @click="close"> mdi-close </v-icon>
          </div>
          <h5 class="text-h5 tway-header-1 text-center px-15" style="word-break: break-word">
            ¡Te damos la bienvenida!
          </h5>
        </v-card-title>
        <v-card-text class="text-center mt-7">
          <div class="px-md-10 px-0 pb-md-16 pb-6">
            <v-img
              class="main-img"
              :src="require(`@/assets/img/onboarding/welcome.svg`)"
              contain
              :max-height="desktop ? 183 : 120"
            />
            <div class="mt-5 text-center text-oboarding">
              {{ welcomeText }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center px-4 pb-5">
          <v-btn large rounded color="tway-violet" class="white--text roboto" @click="firstModal = false">
            SIGUIENTE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-else v-model="show" max-width="37.5rem" persistent>
      <v-card class="onboarding-modal">
        <v-card-title class="d-block">
          <div class="d-flex justify-end">
            <v-icon color="tway-violet" style="cursor: pointer" @click="close"> mdi-close </v-icon>
          </div>
          <h5 class="text-h5 tway-header-1 text-center px-md-10 px-0" style="word-break: break-word; min-height: 4em">
            {{ items[currentItem].title }}
          </h5>
        </v-card-title>
        <v-carousel
          v-model="currentItem"
          :show-arrows="false"
          hide-delimiter-background
          light
          :height="carrouselHeight"
        >
          <v-carousel-item v-for="(item, i) in items" :key="i" :eager="true">
            <div class="px-md-16 px-4 pb-16">
              <div class="mt-16" v-if="!item.text" />
              <v-img class="main-img" :src="item.img" contain :max-height="imgHeight" :min-height="imgHeight" />
              <div class="mt-5 text-center text-oboarding">
                {{ items[currentItem].text }}
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
        <v-card-actions class="d-flex justify-space-between px-4 pb-5">
          <a class="tway-violet--text pa-3 pr-5 roboto font-weight-bold" @click="back"> ATRÁS </a>
          <v-btn
            v-if="currentItem + 1 < items.length"
            large
            rounded
            color="tway-violet"
            class="white--text roboto"
            @click="next"
          >
            SIGUIENTE
          </v-btn>
          <v-btn
            v-else
            large
            rounded
            color="tway-violet"
            class="white--text roboto"
            :style="desktop ? { position: 'absolute', left: '40%' } : {}"
            @click="finish"
          >
            ¡EMPECEMOS!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const WELCOME_TEXTS = {
  client:
    "Antes de ir a tu página de entrada personalizada, te mostramos lo que puedes hacer en Tway para acelerar la transformación de tu empresa.",
  specialist:
    "Comencemos echando un vistazo rápido a las funcionalidades de Tway que podrás usar para encontrar clientes que buscan soluciones que aceleren su Transformación Digital.",
};

const MODAL_ITEMS = {
  client: [
    {
      title: "Haz la Encuesta en Transformación Digital y contrata tu plan",
      text: "Si realizas nuestra encuesta para conocer el Índice de Madurez en Transformación, te mostraremos las dimensiones donde te acompañaremos para potenciar tu negocio y conectar con empresas.",
      img: require(`@/assets/img/onboarding/plans.svg`),
    },
    {
      title: "Recibe tu Índice de Madurez en Transformación Digital",
      text: "Visualiza tu nivel digital de forma clara para poder identificar las dimensiones críticas y oportunidades que impulsarán tu digitalización.",
      img: require(`@/assets/img/onboarding/imtd.svg`),
    },
    {
      title: "Descubre tu dashboard personalizado",
      text: "Accede 24/7 a un panel dinámico con los indicadores y contenidos clave para tu rubro y tipo de empresa, tus desafíos digitales estratégicos y un plan de trabajo automatizado y a la medida.",
      img: require(`@/assets/img/onboarding/dashboard.svg`),
    },
    {
      title: "Levanta un proyecto y haz un match inteligente",
      text: "Crea un proyecto y el algoritmo de Tway te mostrará a los mejores especialistas con soluciones específicas para que tú elijas el mejor match para tus requerimientos.",
      img: require(`@/assets/img/onboarding/match.svg`),
    },
    {
      title: "Recibe acompañamiento y mide tus avances",
      text: "Monitoreamos y guiamos tu proceso con un asistente virtual en Transformación Digital, que además medirá el impacto del proyecto en tu negocio.",
      img: require(`@/assets/img/onboarding/match2.png`),
    },
    {
      title: "¡Comienza la transformación de tu empresa con Tway!",
      text: "",
      img: require(`@/assets/img/onboarding/kickoff.svg`),
    },
  ],
  specialist: [
    {
      title: "Haz la Encuesta en Transformación Digital y contrata tu plan",
      text: "Realiza la encuesta y escoge el mejor Plan para acceder al diagnóstico de madurez digital y a un canal de promoción y venta conectando con empresas que requieren tus servicios en Tway.",
      img: require(`@/assets/img/onboarding/plans.svg`),
    },
    {
      title: "Accede a tu dashboard personalizado y sé parte del ranking de Especialistas",
      text: "Integra el ranking de proveedores en transformación, el que será visible para los clientes. Accede a un dashboard 24/7 con benchmark de tu sector, los servicios en transformación más demandados e indicadores en tiempo real.",
      img: require(`@/assets/img/onboarding/ranking.svg`),
    },
    {
      title: "Completa tu portafolio",
      text: "Ingresa la experiencia y casos de éxito de tu empresa en la sección Mi Portafolio para aumentar tus posibilidades de postular a proyectos y participar de licitaciones de servicios de transformación.",
      img: require(`@/assets/img/onboarding/briefcase.svg`),
    },
    {
      title: "Postula como proveedor especializado en Transformación",
      text: "Tway te notificará de forma automatizada de los proyectos donde tu oferta calce con los requerimientos de los clientes. Postula a las licitaciones y sigue las etapas y progreso en tu dashboard.",
      img: require(`@/assets/img/onboarding/plus.svg`),
    },
    {
      title: "Haz match y recibe acompañamiento",
      text: "Al adjudicar el proyecto para tu empresa recibe acompañamiento y monitorea el proceso de ejecución con el asistente virtual especializado en transformación digital de Tway.",
      img: require(`@/assets/img/onboarding/match.svg`),
    },
    {
      title: "¡Comienza la transformación de tu empresa con Tway!",
      text: "",
      img: require(`@/assets/img/onboarding/kickoff.svg`),
    },
  ],
};

export default {
  name: "OnboardingModal",
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
    type: {
      type: String,
      default: "client",
      required: false,
    },
  },
  data() {
    return {
      firstModal: true,
      currentItem: 0,
      items: [],
      welcomeText: "",
    };
  },
  mounted() {
    this.items = MODAL_ITEMS[this.type.toLowerCase()];
    this.welcomeText = WELCOME_TEXTS[this.type.toLowerCase()];
  },
  methods: {
    next() {
      if (this.currentItem + 1 < this.items.length) {
        this.currentItem++;
      }
    },
    back() {
      if (this.currentItem > 0) {
        this.currentItem--;
      } else if (this.currentItem === 0) {
        this.firstModal = true;
      }
    },
    close() {
      this.$emit("close");
    },
    finish() {
      this.$emit("close");
      this.currentItem = 0;
      this.firstModal = true;
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    imgHeight() {
      if (this.desktop) {
        return this.currentItem + 1 < this.items.length ? 196 : 244;
      } else {
        return 120;
      }
    },
    carrouselHeight() {
      return this.desktop ? 364 : 310;
    },
  },
};
</script>

<style lang="css">
.onboarding-modal {
  border-radius: 18px !important;
}

.onboarding-modal .v-card__actions button {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
  letter-spacing: 0.1em !important;
  font-weight: bold;
}

.text-oboarding {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  text-align: center;
  color: #000000;
}

@media only screen and (max-width: 960px) {
  .text-oboarding {
    font-size: 14px;
  }
  .onboarding-modal .text-h5 {
    font-size: 20px;
  }
}

.onboarding-modal .v-carousel__controls__item {
  margin: 0px 2px !important;
}

.onboarding-modal .v-btn--icon.v-size--small .v-icon,
.v-btn--fab.v-size--small .v-icon {
  font-size: 14px !important;
}

.onboarding-modal .theme--light.v-btn.v-btn--icon.v-btn--active {
  color: #16c62e;
}
</style>
