import companyService from "../../services/companyService";

const CompanyStore = {
  namespaced: true,
  state: {
    company: {},
    completion: 0,
    sizes: [],
    sectors: [],
    businessAreas: [],
    workplaceSeniority: [],
    ageRange: [],
    managements: [],
    categories: [],
    services: [],
    dimensions: [],
    odometer: {},
    rankingSize: 0,
    specialists: [],
  },
  getters: {
    getCompanyType: (state) => state.company.type,
    getCompany: (state) => state.company,
    getCompanyCompletion: (state) => state.completion,
    getCompanySizes: (state) => state.sizes,
    getCompanySectors: (state) => state.sectors,
    getCompanyBusinessAreas: (state) => state.businessAreas,
    getCompanyWorkplaceSeniority: (state) => state.workplaceSeniority,
    getCompanyAgeRange: (state) => state.ageRange,
    getCompanyManagements: (state) => state.managements,
    getCompanyCategories: (state) => state.categories,
    getCompanyServices: (state) => state.services,
    getCompanyDimensions: (state) => state.dimensions,
    getCompanyOdometer: (state) => state.odometer,
    getCompanyRankingSize: (state) => state.rankingSize,
    getCompanySpecialists: (state) => state.specialists,
  },
  actions: {
    init({ commit }) {
      commit("INITIALIZE_COMPANY");
    },
    async setCompanyProfile({ commit }) {
      let company = await companyService.getCompanyProfile();
      commit("ADD_COMPANY", company);
      return company;
    },
    async editCompany(data) {
      await companyService.updateCompanyProfile(data).then(() => {
        return true;
      });
    },
    async setSizes({ commit }, clientId) {
      const companySizes = await companyService.getSizes(clientId);
      commit("SET_COMPANY_SIZE", companySizes.data);
    },
    async setSectors({ commit }, clientId) {
      const companySectors = await companyService.getSectors(clientId);
      commit("SET_COMPANY_SECTOR", companySectors);
    },
    async setBusinessAreas({ commit }, token) {
      const data = await companyService.getCompanyBusinessArea(token);
      commit("SET_COMPANY_BUSINESS_AREAS", data);
    },
    async setWorkplaceSenioritys({ commit }, token) {
      const data = await companyService.getCompanyWorkplaceSeniority(token);
      commit("SET_COMPANY_WORKPLACE_SENIORITY", data);
    },
    async setAgeRanges({ commit }, token) {
      const data = await companyService.getCompanyAgeRange(token);
      commit("SET_COMPANY_AGE_RANGE", data);
    },
    async setManagements({ commit }, token) {
      const data = await companyService.getCompanyManagements(token);
      commit("SET_COMPANY_MANAGEMENTS", data);
    },
    async setCategories({ commit }, payload) {
      const { clientId, withSpecialistsAmount } = payload;
      const data = await companyService.getCategories(clientId, withSpecialistsAmount);
      commit("SET_COMPANY_CATEGORIES", data);
    },
    async setServices({ commit }, payload) {
      const { clientId, withSpecialistsAmount } = payload;
      const data = await companyService.getServices(clientId, withSpecialistsAmount);
      commit("SET_COMPANY_SERVICES", data);
    },
    async setDimensions({ commit }, clientId) {
      const data = await companyService.getDimensions(clientId);
      commit("SET_COMPANY_DIMENSIONS", data);
    },
    async setOdometer({ commit }, specialistId) {
      const data = await companyService.getOdometer(specialistId);
      commit("SET_COMPANY_ODOMETER", data);
    },
    async setRankingSize({ commit }, clientId) {
      const data = await companyService.getRankingSize(clientId);
      commit("SET_COMPANY_RANKING_SIZE", data);
    },
    async setCompanySpecialists({ commit }) {
      const data = await companyService.getSpecialists();
      commit("SET_COMPANY_SPECIALISTS", data);
    },
    setCompanyCompletion({ commit, state }) {
      let array = Object.entries(state.company);
      let total = 6;
      let nullables = 0;
      for (let index = 0; index < array.length; index++) {
        const key = array[index][0];
        const val = array[index][1];
        if (key == "dimensions" && val.length == 0) {
          nullables++;
        }

        if ((key == "trayectory" || key == "contributionValue" || key == "web" || key == "linkedIn") && !val) {
          nullables++;
        }

        if (key == "size" && (!val || (val && !val.id))) {
          nullables++;
        }
      }
      let percentCompany = Math.floor(((total - nullables) / total) * 100);
      commit("SET_COMPANY_COMPLETION", percentCompany);
    },
  },
  mutations: {
    INITIALIZE_COMPANY(state) {
      state.company = {};
    },
    ADD_COMPANY(state, company) {
      state.company = company;
    },
    SET_COMPANY_COMPLETION(state, completion) {
      state.completion = completion;
    },
    SET_COMPANY_SIZE(state, companySizes) {
      state.sizes = companySizes;
    },
    SET_COMPANY_SECTOR(state, companySectors) {
      state.sectors = companySectors;
    },
    SET_COMPANY_BUSINESS_AREAS(state, businessAreas) {
      state.businessAreas = businessAreas;
    },
    SET_COMPANY_WORKPLACE_SENIORITY(state, workplaceSeniority) {
      state.workplaceSeniority = workplaceSeniority;
    },
    SET_COMPANY_AGE_RANGE(state, ageRange) {
      state.ageRange = ageRange;
    },
    SET_COMPANY_MANAGEMENTS(state, managements) {
      state.managements = managements;
    },
    SET_COMPANY_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_COMPANY_SERVICES(state, services) {
      state.services = services;
    },
    SET_COMPANY_DIMENSIONS(state, dimensions) {
      state.dimensions = dimensions;
    },
    SET_COMPANY_ODOMETER(state, odometer) {
      state.odometer = odometer;
    },
    SET_COMPANY_RANKING_SIZE(state, rankingSize) {
      state.rankingSize = rankingSize;
    },
    SET_COMPANY_SPECIALISTS(state, specialists) {
      state.specialists = specialists;
    },
  },
};

export default CompanyStore;
