const state = {
  name: "",
};

const getters = {
  name(state) {
    return state.name;
  },
};

const actions = {
  setLayout({ commit }, name) {
    commit("SET_LAYOUT", name);
  },
};

const mutations = {
  SET_LAYOUT(state, payload) {
    state.name = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
