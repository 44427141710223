import dtiFormConfig from "../../components/forms/typeform/config/dtiFormConfig.json";

const state = {
  formData: {},
};

const getters = {
  getFormData(state) {
    return state.formData;
  },
};

const actions = {
  setFormData({ commit }, payload) {
    commit("UPDATE_FORM_DATA", payload);
  },

  updateField({ commit }, payload) {
    commit("UPDATE_FIELD", payload);
  },

  resetFormData({ commit }, payload) {
    commit("RESET_FORM_DATA", payload);
  },
};

const mutations = {
  UPDATE_FIELD(state, payload) {
    state.formData[payload.key] = {
      label: payload.label,
      value: payload.value,
    };
  },

  RESET_FORM_DATA(state) {
    state.formData = {};
  },

  UPDATE_FORM_DATA(state, payload) {
    state.formData = payload;
  },
};

dtiFormConfig.forEach((field) => {
  state.formData[field.name] = { label: field.label, value: "" };
});

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
};
