const state = {
  lastTransactionId: "",
  token: "",
  buyOrder: "",
  updatedAt: "",
};

const getters = {
  getPendingPayment(state) {
    return JSON.parse(localStorage.getItem("payment")) || state;
  },
};

const actions = {
  setPendingPayment({ commit }, payload) {
    commit("SET_PENDING_PAYMENT", payload);
  },

  updatePendingPayment({ commit }, payload) {
    commit("UPDATE_PENDING_PAYMENT", payload);
  },
};

const mutations = {
  SET_PENDING_PAYMENT(state, payload) {
    state.lastTransactionId = null;
    state.buyOrder = payload.buyOrder;
    state.token = payload.token;
    state.updatedAt = Date.now();
    localStorage.setItem("payment", JSON.stringify(state));
  },

  UPDATE_PENDING_PAYMENT(state, payload) {
    state.buyOrder = null;
    state.token = null;
    state.lastTransactionId = payload;
    state.updatedAt = Date.now();
    localStorage.setItem("payment", JSON.stringify(state));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
