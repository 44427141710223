import InvitationService from "@/services/invitationService";
import dtService from "@/services/dtService";

const invitationsStore = {
  namespaced: true,
  state: {
    modalState: 0,
    openModal: false,
    invitations: [],
    invitedUsers: [],
    invited: {},
    surveyInvitedRegistration: {},
    surveyInvitedContextData: {},
    deleteStatus: {},
    resendStatus: {},
    itemFromTable: {},
  },
  getters: {
    getModalState: (state) => state.modalState,
    getOpenModal: (state) => state.openModal,
    getInvitations: (state) => state.invitations,
    getInvitedUsers: (state) => state.invitedUsers,
    getInvited: (state) => state.invited,
    getSurveyInvitedContextData: (state) => state.surveyInvitedContextData,
    getSurveyInvitedRegistration: (state) => state.surveyInvitedRegistration,
    getDeleteStatus: (state) => state.deleteStatus,
    getResendStatus: (state) => state.reSendInvitation,
    getItemFromTable: (state) => state.itemFromTable,
  },
  actions: {
    setModalState({ commit }, modalState) {
      commit("SET_MODAL_STATE", modalState);
    },
    setOpenModal({ commit }, openModal) {
      commit("SET_OPEN_MODAL", openModal);
    },
    setItemFromTable({ commit }, item) {
      commit("SET_ITEM", item);
    },
    async addInvitations({ commit }, invitations) {
      const invitationsResp = await InvitationService.addInvitation(invitations);
      commit("SET_INVITATION", invitationsResp.data);
    },
    async setSurveyInvitedContextData({ commit }, data) {
      await InvitationService.getInvitation(data.token, data.hash)
        .then((response) => {
          if (response.request.status == 200) {
            commit("SET_SURVEY_INVITED_CONTEXT_DATA", response.data);
          } else {
            commit("SET_SURVEY_INVITED_CONTEXT_DATA", {
              response: response.request.status,
            });
          }
        })
        .catch((err) => (/* eslint-disable */ oo_oo(), console.log(err, `8579b2bc_0`)));
    },
    setSurveyInvitedRegistration({ commit }, data) {
      commit("SET_SURVEY_INVITED_REGISTRATION", data);
    },

    async setInvitedUsers({ commit }, clientId) {
      var promises = [];
      const invitations = await InvitationService.getInvitations(clientId);
      invitations.map((e) => {
        if (e.userId) {
          promises.push(dtService.getSurveyComplete("a123", e.userId));
        }
      });
      const answers = await Promise.all(promises);
      const invitationsMap = new Map();
      answers.forEach((answer) => {
        invitationsMap.set(answer.userId, answer.answered);
      });
      invitations.forEach((element) => {
        const answered = invitationsMap.get(element.userId);
        element.answers = answered ? answered : 0;
      });

      commit("SET_INVITED_USERS", invitations);
    },
    async setInvitation({ commit }, token, hash) {
      await InvitationService.getInvitation(token, hash)
        .then((response) => {
          commit("SET_INVITED", response);
          return { ...response };
        })
        .catch((err) => (/* eslint-disable */ oo_oo(), console.log(err, `8579b2bc_1`)));
    },
    async deleteInvitedUsers({ commit }, invitationId) {
      await InvitationService.deleteInvitation(invitationId)
        .then((response) => {
          commit("SET_DELETE_INVITATION", response);
          return { ...response };
        })
        .catch((err) => (/* eslint-disable */ oo_oo(), console.log("store", err, `8579b2bc_2`)));
    },
    async reSendInvitation({ commit }, invitationId) {
      await InvitationService.reSendInvitation(invitationId)
        .then((response) => {
          commit("SET_RESEND_INVITATION", response);
          return { ...response };
        })
        .catch((err) => (/* eslint-disable */ oo_oo(), console.log("store", err, `8579b2bc_3`)));
    },
    cleanInvitations({ commit }) {
      commit("SET_INVITATIONS_CLEAN");
    },
  },
  mutations: {
    SET_ITEM(state, item) {
      state.itemFromTable = item;
    },
    SET_MODAL_STATE(state, modalState) {
      state.modalState = modalState;
    },
    SET_OPEN_MODAL(state, openModal) {
      state.openModal = openModal;
    },
    SET_INVITATION(state, invitations) {
      state.invitations = invitations;
    },
    SET_SURVEY_INVITED_CONTEXT_DATA(state, data) {
      state.surveyInvitedContextData = data;
    },
    SET_SURVEY_INVITED_REGISTRATION(state, data) {
      state.surveyInvitedRegistration = data;
    },
    SET_INVITATIONS_CLEAN(state) {
      state.invitations = [];
    },
    SET_RESEND_INVITATION(state, resendStatus) {
      state.resendStatus = resendStatus;
    },
    SET_DELETE_INVITATION(state, deleteStatus) {
      state.deleteStatus = deleteStatus;
    },

    SET_INVITED_USERS(state, invitedUsers) {
      state.invitedUsers = invitedUsers;
    },
    SET_INVITED(state, invitedUser) {
      state.invited = invitedUser;
    },
  },
};

export default invitationsStore;
