<template>
  <v-app>
    <app-header @update="drawer = $event" :app-name="'Tway'" :show-modal="showModal" />
    <v-main
      :style="
        (drawer && desktop ? 'padding: 100px 0px 0px 100px !important;' : '') + ' ' + (!desktop ? '' : 'height: 100vh;')
      "
    >
      <v-container
        fluid
        id="main-scroll"
        class="pa-0"
        :class="desktop ? 'scroll' : 'scroll'"
        :style="desktop ? '' : 'height: 80vh;'"
      >
        <router-view
          :class="this.$route.name == 'getabstract' || this.$route.name == 'survey' ? 'pa-0' : 'pa-5'"
          :style="{ height: this.$route.name == 'dti-form' ? '100%' : null }"
        />
      </v-container>
    </v-main>
    <app-footer />
    <div v-if="!desktop" class="mobile-footer py-4 px-4 tway-violet-dark">
      <v-img v-if="!drawer" :src="require('@/assets/img/Tway-logotipo-gris.png')" contain max-width="90px" />
      <div>
        <v-btn
          color="white"
          @click="
            () => {
              showModal = !showModal;
            }
          "
          class="button-text elevation-0"
          block
          rounded
          outlined
        >
          <span>Cerrar Sesión</span>
        </v-btn>
      </div>
    </div>
  </v-app>
</template>

<style lang="scss" scoped>
.alert-promo {
  background: linear-gradient(125.19deg, #119a24 0%, #7319d5 100%);
}
.alert-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* shades / white */

  color: #ffffff;
}

.alert-span {
  margin-left: 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Tway / Verde */

  color: #aeea00;
}

.mobile-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 20px;
    background-color: rgba(#cccccc, 0.7);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #cccccc;
    border: 4px solid rgba(0, 0, 0, 0);
    cursor: pointer;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import { mapActions } from "vuex";

export default {
  name: "AppLayout",
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      drawer: false,
      showModal: false,
      promo: true,
    };
  },
  methods: {
    ...mapActions("authentication", ["doLogout"]),
    logout() {
      this.doLogout()
        .then(() => this.$router.push({ name: "login" }))
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
  },
  computed: {
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
