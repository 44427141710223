import Vue from "vue";
import uaaAxios from "@/utils/authAxios";
import companyService from "@/services/companyService";

const dtService = {
  /* DT Survey */
  getSurvey: function (surveyCode) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${process.env.VUE_APP_SUR_API}/surveys?code=${surveyCode}`)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  getPercentage: function (surveyId, userId) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${process.env.VUE_APP_SUR_API}/metrics/surveys/${surveyId}/users/${userId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getSurveyComplete: async (surveyCode, userId) => {
    let response = await uaaAxios.get(`${process.env.VUE_APP_SUR_API}/surveys/code/${surveyCode}/users/${userId}`);
    return response.data;
  },

  postAnswers: function (array) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_SUR_ANS_API}/surveyanswer/page`, array)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },

  triggerDti: function () {
    return new Promise((resolve, reject) => {
      companyService
        .getCompanyProfile()
        .then((clientProfile) => {
          let data = {
            customerId: clientProfile.tributaryId,
            dimension: "Clientes",
            strategy: null,
          };

          uaaAxios
            .post(`${process.env.VUE_APP_DT_API}/customer-maturity/`, data)
            .then((response) => resolve(Vue._.get(response, "data")))
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  },

  /* DT Index/Dimensions values */
  getDti: function (userId, surveyId, firstDti) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    let firstDtiParam = firstDti !== undefined && firstDti !== null ? firstDti : false;
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }
    return new Promise((resolve, reject) => {
      companyService
        .getCompanyProfile()
        .then((clientProfile) => {
          let clientTributaryId = clientProfile.tributaryId;
          uaaAxios
            .get(
              `${process.env.VUE_APP_DT_API}/customer-maturity/clients/${clientTributaryId}/${userId}/${surveyId}?clientId=${clientId}&firstDti=${firstDtiParam}`
            )
            .then((response) => resolve(Vue._.get(response, "data")))
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  },

  getRanking: function (type, sector) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(
          `${process.env.VUE_APP_DT_API}/customer-maturity/ranking?` +
            `${sector ? "sectorName=" + sector + "&" : ""}` +
            `companyType=${type}&clientId=${clientId}`
        )
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },

  getPosition: function (sectorName) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let clientId = "";
    if (userInfo && userInfo.principal && userInfo.principal.clientId) {
      clientId = userInfo.principal.clientId;
    }
    return new Promise((resolve, reject) => {
      companyService
        .getCompanyProfile()
        .then((clientProfile) => {
          let clientTributaryId = clientProfile.tributaryId;
          uaaAxios
            .get(
              `${process.env.VUE_APP_DT_API}/customer-maturity/my-position` +
                "?clientTributaryId=" +
                clientTributaryId +
                (sectorName ? "&sectorName=" + sectorName : "") +
                `&clientId=${clientId}`
            )
            .then((response) => resolve(Vue._.get(response, "data")))
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  },

  getClientDimension: function (dimension) {
    return new Promise((resolve, reject) => {
      companyService
        .getCompanyProfile()
        .then((clientProfile) => {
          let clientId = clientProfile.tributaryId;
          let dimensionId = dimension;

          uaaAxios
            .get(`${process.env.VUE_APP_DT_API}/customer-maturity/clients/${clientId}/dimensions/${dimensionId}`)
            .then((response) => resolve(Vue._.get(response, "data")))
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  },

  getCategories: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${process.env.VUE_APP_SUR_API}/categories`)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },

  getCategoriesBySub: function (subdimensionId) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${process.env.VUE_APP_SUR_API}/subdimensions/categories/${subdimensionId}`)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  getCategoriesBySubDimensionNma: async (name) => {
    try {
      const response = await uaaAxios.get(
        `${process.env.VUE_APP_SUR_API}/subdimensions/categories/subdim-name/${name}`
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  getRecomendationsByClient: function (clientId, surveyCode, management, businessArea, geoSector) {
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_RAPI}/client-recommendations/${clientId}/${surveyCode}`;
      if (businessArea) {
        url = url + "?businessArea=" + businessArea;
      } else if (management) {
        url = url + "?management=" + management;
      } else if (geoSector) {
        url = url + "?geoSector=" + geoSector;
      }
      uaaAxios
        .get(url)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async getRecomendationsBci(clientId, surveyCode, filterCriteria) {
    try {
      const response = await uaaAxios.post(
        `${process.env.VUE_APP_RAPI}/client-recommendations/${clientId}/${surveyCode}`,
        { filterCriteria }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
};

export default dtService;
