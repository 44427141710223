class AbstractError extends Error {
  /**
   *
   * @param {*} message
   * @param {*} errorCode
   */
  constructor(message, errorCode) {
    super(message);
    this.errorCode = errorCode;
  }
}

export default AbstractError;
