import Vue from "vue";
import axios from "axios";
import qs from "qs";

const authClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL_BASE,
});

const LoginService = {
  /**
   * Servicio para realizar login
   *
   * @param {*} username
   * @param {*} password
   * @param {*} grandTye
   * @returns
   */
  login: async (username, password, grandTye) => {
    const data = qs.stringify({
      grant_type: grandTye,
      username: username,
      password: password,
      scope: "ui",
    });

    const { data: authData } = await authClient.post("/uaa/oauth/token", data, {
      auth: {
        username: process.env.VUE_APP_CLIENT_ID,
        password: process.env.VUE_APP_SECRET,
      },
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    return authData;
  },
  /**
   *
   * @param {*} grandTye
   * @param {*} refreshToken
   * @returns
   */
  refreshToken: async (grandTye, refreshToken) => {
    console.log("pase por el refresh token");
    const data = qs.stringify({
      grant_type: grandTye,
      refresh_token: refreshToken,
    });

    const { data: authData } = await authClient.post("/uaa/oauth/token", data, {
      auth: {
        username: process.env.VUE_APP_CLIENT_ID,
        password: process.env.VUE_APP_SECRET,
      },
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    return authData;
  },
  register: function (
    firstname,
    lastname,
    companyName,
    companyIdentifier,
    email,
    clientType,
    countryCode,
    source,
    token,
    businessParams,
    promo,
    metadata
  ) {
    return new Promise((resolve) => {
      let data = {
        username: email,
        firstName: firstname,
        lastName: lastname,
        businessParams,
        promo,
        client: {
          name: companyName,
          tributaryId: companyIdentifier,
          clientType: clientType,
          countryCode: countryCode,
          source: source,
        },
        metadata: metadata,
      };
      axios
        .post(
          `${process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_ONBOARDING_API}/users?recaptchaResponse=${token}`,
          data
        )
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((error) => {
          resolve(error);
        });
    });
  },

  invitedUserRegister: async (data, token, invitationHash) => {
    try {
      const { responseData } = await axios.post(
        `${
          process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_ONBOARDING_API
        }/users/invitations?recaptchaResponse=${token}&invitation-hash=${invitationHash}`,
        data
      );
      return { ...responseData };
    } catch (error) {
      return { ...error };
    }
  },
  invitedSurveyRegister: async (data, token, invitationHash) => {
    try {
      const { responseData } = await axios.post(
        `${
          process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_ONBOARDING_API
        }/surveyuser?recaptchaResponse=${token}&invitation-hash=${invitationHash}`,
        data
      );
      return { ...responseData };
    } catch (error) {
      return { ...error };
    }
  },

  activate: function (userId, password) {
    return new Promise((resolve) => {
      axios
        .post(`${process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_ONBOARDING_API}/activate/${userId}`, {
          password,
        })
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((error) => {
          Vue.$log.error("Error Status: ", Vue._.get(error, "status"));
          resolve(error);
        });
    });
  },

  validateUser: function (userId) {
    return new Promise((resolve) => {
      axios
        .get(`${process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_ONBOARDING_API}/activate/${userId}`)
        .then((response) => {
          resolve(Vue._.get(response, "data"));
        })
        .catch((error) => {
          Vue.$log.error("Error Status: ", Vue._.get(error, "status"));
          resolve(error);
        });
    });
  },

  /**
   * Valida el token obtenido
   */
  validateToken: function (token) {
    if (token && Vue._.get(token, "length") > 0) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_URL_BASE + "/uaa/oauth/check_token", {
            params: { token },
            auth: {
              username: process.env.VUE_APP_CLIENT_ID,
              password: process.env.VUE_APP_SECRET,
            },
          })
          .then(() => resolve(true))
          .catch((err) => reject(err));
      });
    }
  },

  sendPasswordRecoveryEmail: function (email, captcha) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_UMAPI_API}/passwords/recovery`, {
          username: email,
          recaptcha: captcha,
        })
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },

  checkPasswordToken(token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_UMAPI_API}/users/check-password-token/${token}`)
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },

  initChangePassword(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_UMAPI_API}/passwords/recovery/${id}`)
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },

  recoverPasswordByToken(id, pass, confirm, captcha) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL_BASE + process.env.VUE_APP_UMAPI_API}/passwords/recover-password/`, {
          recoveryPasswordId: id,
          password: pass,
          passwordConfirm: confirm,
          recaptcha: captcha,
        })
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },
};

export default LoginService;
