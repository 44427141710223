import CallForTenderService from "@/services/callForTenderService";

const STEP_STATUS = {
  incomplete: 0,
  inProgress: 1,
  complete: 2,
};

const INITIAL_STATE = {
  foundationData: {
    dimension: null,
    subDimension: null,
  },
  projectFromDB: null,
  steps: [
    {
      path: "GeneralData",
      status: STEP_STATUS.inProgress,
      data: null,
    },
    {
      path: "improvements",
      status: STEP_STATUS.incomplete,
      data: null,
    },
    {
      path: "Requeriments",
      status: STEP_STATUS.incomplete,
      data: null,
    },
    {
      path: "Confirmation",
      status: STEP_STATUS.incomplete,
      data: null,
    },
    {
      path: "Finish",
      status: STEP_STATUS.incomplete,
      data: null,
    },
  ],
};

const state = {
  steps: [...INITIAL_STATE.steps],
  createResponse: {},
};

const getters = {
  getProject(state) {
    let project = {};
    for (let step of state.steps) {
      if (step.data) {
        project = { ...project, ...step.data };
      }
    }
    return { ...state.projectFromDB, ...project };
  },
  getStepIsLocked: (state) => (stepIndex) => state.steps[stepIndex].status === STEP_STATUS.incomplete,
  getStepIsComplete: (state) => (stepIndex) => state.steps[stepIndex].status === STEP_STATUS.complete,
  getStep: (state) => (stepIndex) => state.steps[stepIndex],
  getIsLastStep: (state) => (stepIndex) => stepIndex === state.steps.length - 2,
  getFormData(state) {
    let data = {};
    for (let step of state.steps) {
      data = { ...data, ...step.data };
    }
    return data;
  },
  getLastComplete(state) {
    let lastComple = {};
    state.steps.forEach((step, index) => {
      if (step.status === STEP_STATUS.inProgress) {
        lastComple = { step, index };
      }
    });
    return lastComple;
  },
  getIsAllComplete(state) {
    let project = {};
    for (let step of state.steps) {
      if (step.data) {
        project = { ...project, ...step.data };
      }
    }
    project = { ...state.projectFromDB, ...project };

    if (
      project.name &&
      project.owner &&
      project.summary &&
      project.businessGoal &&
      project.proyectGoal &&
      project.scope &&
      project.qualitativeImprovements &&
      project.quantitativeImprovements &&
      project.evaluationCriteria &&
      project.estimatedBudged &&
      project.period &&
      project.technicalRequirements &&
      project.status.id < 3
    ) {
      return true;
    }
    return false;
  },
};
const actions = {
  async createProject({ commit }, formData) {
    try {
      const response = await CallForTenderService.createProject(formData);
      commit("CREATE_PROJECT", response);
    } catch (error) {
      console.log(error);
    }
  },
  setInProgress({ commit }, index) {
    commit("SET_STEP_STATUS", { index, status: STEP_STATUS.inProgress });
  },
  setComplete({ commit }, index) {
    commit("SET_STEP_STATUS", { index, status: STEP_STATUS.complete });
  },
  setIncomplete({ commit }, index) {
    commit("SET_STEP_STATUS", { index, status: STEP_STATUS.incomplete });
  },
  unlockStep({ commit }, index) {
    commit("UNLOCK_STEP", index);
  },
  setStepData({ commit }, args) {
    commit("SET_STEP_DATA", args);
  },
  setProjectFromDB({ commit }, project) {
    commit("SET_PROJECT_DB", project);
  },
  setFormDataFromProject({ commit }, project) {
    commit("SET_FORM_DATA_FROM_PROJECT", project);
  },
  resetStep({ commit }, index) {
    commit("SET_STEP_DATA", { index, data: null });
    commit("SET_STEP_STATUS", { index, status: STEP_STATUS.incomplete });
  },
  resetAll({ commit }) {
    commit("RESET_ALL");
  },
  updateProject({ commit }) {
    commit("UPDATE_PROJECT");
  },
};

const mutations = {
  CREATE_PROJECT(state, response) {
    state.createResponse = response;
  },
  SET_STEP_STATUS(state, payload) {
    state.steps[payload.index].status = payload.status;
  },
  SET_STEP_DATA(state, payload) {
    state.steps[payload.index].data = payload.data;
    state.projectFromDB = { ...state.projectFromDB, ...payload.data };
  },
  SET_PROJECT_DB(state, project) {
    state.projectFromDB = project;
  },
  SET_FORM_DATA_FROM_PROJECT(state, project) {
    state.projectFromDB = project;
    let steps = state.steps;

    steps[0].status = STEP_STATUS.complete;
    steps[1].status = STEP_STATUS.inProgress;
    steps[0].data = {
      name: project.name,
      owner: project.owner,
      summary: project.summary,
      businessGoal: project.businessGoal,
      proyectGoal: project.proyectGoal,
      scope: project.scope,
    };

    steps[1].status = STEP_STATUS.complete;
    steps[2].status = STEP_STATUS.inProgress;
    steps[1].data = {
      qualitativeImprovements: project.qualitativeImprovements,
      quantitativeImprovements: project.quantitativeImprovements,
      evaluationCriteria: project.evaluationCriteria,
      estimatedBudged: project.estimatedBudged ? project.estimatedBudged : { value: 0, currency: "" },
      period: project.period,
    };

    steps[2].status = STEP_STATUS.complete;
    steps[3].status = STEP_STATUS.inProgress;
    steps[2].data = {
      technicalRequirements: project.technicalRequirements,
      aditionalAttachment: project.aditionalAttachment,
    };
  },
  UNLOCK_STEP(state, index) {
    if (state.steps[index].status === STEP_STATUS.incomplete) {
      state.steps[index].status = STEP_STATUS.inProgress;
    }
  },
  RESET_ALL(state) {
    state.steps.forEach((s) => {
      s.data = null;
      s.status = STEP_STATUS.incomplete;
    });
    state.steps[0].status = STEP_STATUS.inProgress;
    state.projectFromDB = null;
  },
  UPDATE_PROJECT(state) {
    for (let step of state.steps) {
      if (step.data) {
        state.projectFromDB = { ...state.projectFromDB, ...step.data };
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
