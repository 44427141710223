import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import es from "vuetify/es5/locale/es";

const vuetify = new Vuetify({
  lang: {
    locales: { es },
    current: "es",
  },
  theme: {
    themes: {
      light: {
        // Primario - violeta (violet)
        "tway-violet": "#7319d5",
        // Secundario - violeta
        "tway-violet-dark": "#4A148C",
        // Primario - Gris (gray)
        "tway-gray": "#f1f1f1",
        "tway-gray-dark": "#333333",
        // Secundario - Morado (purple)
        "tway-purple": "#4a148c",
        // Secundario - Verde (Green)
        "tway-green": "#aeea00",
        // Secundario - Verde Manzana (Apple Green):
        "tway-apple-green": "#16c62e",
        // desde figma
        "tway-violet-2": "#b585e9",
        "tway-violet-3": "#d1bbea",
        "tway-violet-4": "#e9e3ef",
      },
    },
  },
});

export default vuetify;
