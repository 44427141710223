import router from "@/router";
import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL_BASE,
});

export const setAuthHeader = (token) => {
  authAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

authAxios.interceptors.request.use(
  (config) => {
    let authInfo = JSON.parse(localStorage.getItem("authInfo"));
    if (authInfo) {
      config.headers["Authorization"] = `Bearer ${authInfo.access_token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => Promise.reject(error)
);

authAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      if (window.location.pathname !== "/login") {
        router.push("/login");
        return;
      }
    }
    return Promise.reject(error);
  }
);

export default authAxios;
