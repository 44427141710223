const OnlyNumberValidatorService = {
  /**
   *
   * @param {*} evt
   * @returns
   */
  validateFromKey: (evt) => {
    let regex = new RegExp(/[^\d]/g);
    let allowedKeys = ["backspace", "arrowleft", "arrowright", "escape", "tab"];

    if (allowedKeys.indexOf(evt.key.toLowerCase()) !== -1) {
      return true;
    }

    if (evt.ctrlKey || evt.metaKey) {
      return (
        evt.key.toLowerCase() === "v" ||
        evt.key.toLowerCase() === "x" ||
        evt.key.toLowerCase() === "a" ||
        evt.key.toLowerCase() === "c"
      );
    }
    return !regex.test(evt.key);
  },
  /**
   *
   * @param {*} evt
   * @returns
   */
  validateFromKeyCode: (evt) => {
    if (
      [46, 8, 9, 27, 13, 110].indexOf(evt.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (evt.keyCode === 65 && (evt.ctrlKey || evt.metaKey)) ||
      // Allow: Ctrl+C
      (evt.keyCode === 67 && (evt.ctrlKey || evt.metaKey)) ||
      // Allow: Ctrl+V
      (evt.keyCode === 86 && (evt.ctrlKey || evt.metaKey)) ||
      // Allow: Ctrl+X
      (evt.keyCode === 88 && (evt.ctrlKey || evt.metaKey)) ||
      // Allow: home, end, left, right
      (evt.keyCode >= 35 && evt.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return true;
    }
    // Number validation
    return !((evt.shiftKey || evt.keyCode < 48 || evt.keyCode > 57) && (evt.keyCode < 96 || evt.keyCode > 105));
  },
};

export default OnlyNumberValidatorService;
