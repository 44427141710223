import Vue from "vue";
import planService from "@/services/planService";

const state = {
  plans: {},
  plansByType: {},
  activePlans: {},
  suscriptions: {},
};
const getters = {
  getPlans: (state) => state.plans,
  getActivePlans: (state) => state.activePlans,
  getSuscriptions: (state) => state.suscriptions,
};
const actions = {
  setPlans({ commit }) {
    planService
      .getPlans()
      .then((response) => {
        commit("SET_PLANS", response);
      })
      .catch((err) => Vue.$log.error(err));
  },
  setSuscriptions({ commit }) {
    planService
      .getSuscription()
      .then((response) => {
        commit("SET_SUSCRIPTIONS", response);
      })
      .catch((err) => Vue.$log.error(err));
  },
  async setPlansByType({ commit }, companyType) {
    try {
      const plans = await planService.getPlansType(companyType);
      commit("SET_PLANS_BY_TYPE", plans);
      commit("SET_ACTIVE_PLANS", plans);
    } catch (error) {
      console.log(error);
    }
  },
};
const mutations = {
  SET_PLANS(state, data) {
    state.plans = data;
  },
  SET_ACTIVE_PLANS(state, data) {
    state.activePlans = data.filter((plan) => plan.status == "ACTIVE" && plan.name !== "NOPLAN");
  },
  SET_PLANS_BY_TYPE(state, data) {
    state.plansByType = data;
  },
  SET_SUSCRIPTIONS(state, data) {
    state.suscriptions = data;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
