import DtService from "@/services/dtService";
import companyService from "@/services/companyService";

const DigitalTransformationStore = {
  namespaced: true,
  state: {
    questions: {},
    percentageImo: 0,
    surveyInvitedAnswers: [],
    rankingYearsClient: 0,
    rankingByIndustrialSector: [],
    ranking: [],
    rankingSectorNumber: 0,
    rankingGlobalNumber: 0,
    categoriesBySubdimensionId: [],
    categoriesBySubdimensionName: [],
    dti: {},
    survey: {},
  },
  getters: {
    answered: (state) => state.questions.answered || 0,
    total: (state) => state.questions.total || 0,
    percentage: (state) => {
      if (state.total === 0) {
        return 0;
      }
      return Math.floor((state.questions.answered / state.questions.total) * 100);
    },
    percentageImo: (state) => state.percentageImo,
    getSurveyInvitedAnswers: (state) => state.surveyInvitedAnswers,
    getRankingYearsClient: (state) => state.rankingYearsClient,
    getRankingByIndustrialSector: (state) => state.rankingByIndustrialSector,
    getRankingSectorNumber: (state) => state.rankingSectorNumber,
    getRankingGlobalNumber: (state) => state.rankingGlobalNumber,
    getRankingGlobal: (state) => state.ranking,
    getCategoriesBySubdimensionId: (state) => state.categoriesBySubdimensionId,
    getCategoriesBySubdimensionName: (state) => state.categoriesBySubdimensionName,
    getDti: (state) => state.dti,
    getSurvey: (state) => state.survey.content[0],
  },
  actions: {
    async setSurvey({ commit }, surveyCode) {
      try {
        const response = await DtService.getSurvey(surveyCode);
        commit("SET_SURVEY", response);
      } catch (error) {
        console.log(error);
      }
    },
    async setDti({ commit }, data) {
      try {
        const response = await DtService.getDti(data.userId, data.surveyId);
        commit("SET_DTI", response);
      } catch (error) {
        console.log(error);
      }
    },
    async setCategoriesBySubdimensionId({ commit }, id) {
      try {
        const response = await DtService.getCategoriesBySub(id);
        commit("SET_CATEGORIES_BY_SUBDIMENSION", response);
      } catch (error) {
        console.log(error);
      }
    },
    async setCategoriesBySubdimensionName({ commit }, name) {
      try {
        const response = await DtService.getCategoriesBySubDimensionNma(name);
        commit("SET_CATEGORIES_BY_SUBDIMENSION_NAME", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async cleanAnswers({ commit }) {
      commit("SET_CLEAN_ANSWERS");
    },
    async setRankingYearsClient({ commit }, clientId) {
      try {
        const rankingYears = await companyService.getRankingYearsClient(clientId);
        commit("SET_RANKING_YEARS_CLIENT", rankingYears.data);
      } catch (error) {
        console.log(error);
      }
    },
    async setRankingSectorNumber({ commit }, data) {
      try {
        let industrialSector = data.industrialSector.name;
        const position = await DtService.getPosition(industrialSector);
        commit("SET_RANKING_SECTOR_NUMBER", position);
      } catch (error) {
        console.log(error);
      }
    },
    async setRankingGlobalNumber({ commit }) {
      try {
        const position = await DtService.getPosition();
        commit("SET_RANKING_GLOBAL_NUMBER", position);
      } catch (error) {
        console.log(error);
      }
    },
    async setRankingByIndustrialSector({ commit }, data) {
      try {
        const { type, industrialSector } = data;
        const position = await DtService.getRanking(type, industrialSector.name);
        commit("SET_RANKING_BY_SECTOR", position);
      } catch (error) {
        console.log(error);
      }

      /* let rankingSector = [];
      
        .then((response) => {
          rankingSector = response.map((e) => {
            e.name = "";
            return e;
          });
          rankingSector.forEach(async (e, i) => {
            await companyService
              .getCompanyName(e.clientTributaryId)
              .then((companyName) => {
                rankingSector[i].name = companyName;
              })
              .catch(() => {
                rankingSector[i].name = "Sin nombre";
              });
          });
        }) */
    },
    async setRankingGlobal({ commit }, type) {
      const ranking = await DtService.getRanking(type);
      commit("SET_RANKING_GLOBAL", ranking);
    },
    setSurveyInvitedAnswers({ commit }, answers) {
      commit("SET_SURVEY_INVITED_ANSWERS", answers);
    },
    init({ commit }) {
      commit("INITIALIZE_DT");
    },
    async surveyComplete({ commit }, userId) {
      let questions = await DtService.getSurveyComplete("a123", userId);
      let percentageImo = await DtService.getSurveyComplete("imo-index-v1", userId).then((data) => {
        return Math.floor((data.answered / data.total) * 100);
      });

      commit("ADD_QUESTIONS", questions);
      commit("ADD_PERCENTAGE", percentageImo);
    },
  },
  mutations: {
    SET_SURVEY(state, response) {
      state.survey = response;
    },
    SET_DTI(state, response) {
      state.dti = response;
    },
    SET_CATEGORIES_BY_SUBDIMENSION(state, response) {
      state.categoriesBySubdimensionId = response;
    },
    SET_CATEGORIES_BY_SUBDIMENSION_NAME(state, response) {
      state.categoriesBySubdimensionName = response;
    },
    SET_CLEAN_ANSWERS(state) {
      state.surveyInvitedAnswers = [];
    },
    SET_RANKING_YEARS_CLIENT(state, response) {
      state.rankingYearsClient = response;
    },
    SET_RANKING_GLOBAL(state, ranking) {
      state.ranking = ranking;
    },
    SET_RANKING_GLOBAL_NUMBER(state, rankingGlobalNumber) {
      state.rankingGlobalNumber = rankingGlobalNumber;
    },
    SET_RANKING_SECTOR_NUMBER(state, rankingSectorNumber) {
      state.rankingSectorNumber = rankingSectorNumber;
    },
    SET_RANKING_BY_SECTOR(state, rankingBySector) {
      state.rankingByIndustrialSector = rankingBySector;
    },
    SET_SURVEY_INVITED_ANSWERS(state, answers) {
      state.surveyInvitedAnswers = [...state.surveyInvitedAnswers, ...answers];
    },
    INITIALIZE_DT(state) {
      state.questions = {};
      state.percentageImo = null;
    },
    ADD_QUESTIONS(state, questions) {
      state.questions = questions;
    },
    ADD_PERCENTAGE(state, percentageImo) {
      localStorage.setItem("percentageImo", percentageImo);
      state.percentageImo = percentageImo;
    },
  },
};

export default DigitalTransformationStore;
