import DtService from "../../services/dtService";

const ImtdStore = {
  namespaced: true,
  state: {
    score: 0,
    level: "",
    description: "",
    tooltip: "",
    dimensions: [],
  },
  getters: {
    getScore: (state) => state.score,
    getLevel: (state) => state.level,
    getDescription: (state) => state.description,
    getTooltip: (state) => state.tooltip,
    getDimensions: (state) => state.dimensions,
  },
  actions: {
    async setRecommendations({ commit }, data) {
      let surveyCode = "a123";
      await DtService.getRecomendationsByClient(
        data.clientId,
        surveyCode,
        data.filterCriteria ? data.filterCriteria.management : "",
        data.filterCriteria ? data.filterCriteria.businessArea : ""
      ).then((recomendations) => {
        commit("SET_RECOMMENDATIONS", recomendations);
      });
    },
    async setRecommendationsBci({ commit }, data) {
      let surveyCode = "a123";
      await DtService.getRecomendationsBci(
        data.clientId,
        surveyCode,
        data.filterCriteria ? data.filterCriteria : ""
      ).then((recomendations) => {
        commit("SET_RECOMMENDATIONS", recomendations.data);
      });
    },
  },
  mutations: {
    SET_RECOMMENDATIONS(state, recommendations) {
      state.score = recommendations.indexInfo.score;
      state.level = recommendations.indexInfo.levelName;
      state.description = recommendations.generalAdvice;
      state.tooltip = recommendations.tooltip;
      state.dimensions = recommendations.dimensionAdvices;
    },
  },
};

export default ImtdStore;
