import TrendService from "@/services/trendService";

const state = {
  staticTrends: {},
  gaTrends: [],
};

const getters = {
  getStaticTrends: (state) => state.staticTrends,
  getGATrends: (state) => state.gaTrends,
};

const actions = {
  async setStaticTrends({ commit }) {
    try {
      const trends = await TrendService.getTrends();
      let posts = {};
      posts.opinion = trends.filter((e) => e.category == "COLUMN");
      posts.current = trends.filter((e) => e.category != "COLUMN");
      posts.current.sort((a, b) => {
        let d1 = new Date(a.createdOn);
        let d2 = new Date(b.createdOn);

        return d2 > d1 ? 1 : -1;
      });
      posts.opinion.sort((a, b) => {
        let d1 = new Date(a.createdOn);
        let d2 = new Date(b.createdOn);

        return d2 > d1 ? 1 : -1;
      });
      commit("SET_STATIC_TRENDS", posts);
    } catch (error) {
      console.log(error);
    }
  },
  async setGATrends({ commit }, language = "es") {
    try {
      const gaTrends = await TrendService.getGATrends(language);
      commit("SET_GA_TRENDS", gaTrends);
    } catch (error) {
      console.log(error);
    }
  },
};
const mutations = {
  SET_STATIC_TRENDS(state, payload) {
    state.staticTrends = payload;
  },
  SET_GA_TRENDS(state, payload) {
    state.gaTrends = payload;
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
};
